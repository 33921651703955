import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { NotificationState } from 'src/@types/notifications/notifications';
import { dispatch } from 'src/redux/store';

const initialState: NotificationState = {
    isLoading: false,
    error: null,
    data: { notifications: [], total_count: 0 }
};

const slice = createSlice({
    name: 'app_notification',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // GET
        getNotificationsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // DELETE
        deleteNotification: (state, action) => {
            state.isLoading = false;
            state.data.notifications = state.data.notifications.filter(notification => notification.id !== action.payload);
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getNotifications(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/notifications`, { params: params });
            dispatch(slice.actions.getNotificationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acknowledgeNotification(id: string) {
    return async () => {
        try {
            await axios.post(`/notifications/${id}/acknowledge`);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function deleteNotification(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/notifications/${id}`);
            dispatch(slice.actions.deleteNotification(id));
            await dispatch(getNotifications({ offset: 0, limit: 250, sort: '-date' }));
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function deleteBulkNotifications(ids: string[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(`/notifications/bulk_delete`, { ids });
            await dispatch(getNotifications({ offset: 0, limit: 250, sort: '-date' }))
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}
