import { createSlice } from '@reduxjs/toolkit';
import { Client, ClientsState } from 'src/@types/client/client';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: ClientsState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: {
        summary: {
            company: 0,
            individual: 0,
            total: 0
        },
        clients: [],
        total_count: 0
    }
};

const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getClientsSuccess: (state, action) => {
            state.isLoading = false;
            state.data.clients = action.payload.clients;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // CREATE EVENTS
        createClientSuccess: (state, action) => {
            state.isLoading = false;
            state.data.clients = state.data.clients ? [...state.data.clients, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // FETCHED SINGLE CLIENT
        getClientSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.clients) state.data.clients = state.data.clients.filter(client => client.id !== action.payload.id)
            state.data.clients = state.data.clients ? [...state.data.clients, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // UPDATE EVENT
        updateClientsSuccess: (state, action) => {
            state.isLoading = false;
            state.data.clients = state.data.clients?.map(client => client.id === action.payload.id ? action.payload : client) ?? [];
            return state;
        },

        // DELETE NOTE
        deleteNoteClientsSuccess: (state, action) => {
            state.isLoading = false;
            state.data.clients = state.data.clients?.map(client => {
                if (client.id === action.payload.cid) {
                    const additional_notes = [...client.additional_notes];
                    additional_notes.splice(action.payload.nIndex, 1);
                    return { ...client, additional_notes: additional_notes };
                }
                return client;
            }) ?? [];
            return state;
        },

        // DELETE CLIENT
        deleteClientSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.clients) {
                state.data.clients = state.data.clients.filter(client => client.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
            return state;
        },

        // DELETE ATTACHMENT
        attachmentDeleted: (state, action) => {
            console.log(action.payload)
            state.isLoading = false;
            state.data.clients = state.data.clients?.map(client => client.id === action.payload.cid ? { ...client, attachments: client.attachments.filter(a => a !== client.attachments[action.payload.index]) } : client) ?? [];
            return state;
        },

        // set clients in store
        setClients: (state, action) => {
            state.isLoading = false;
            state.data.clients = action.payload;
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // GET EVENTS
        getClientsSummarySuccess: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getClients(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/clients`, { params, cancelToken });
            if (response.data) {
                const { clients = [], total_count = 0 } = response.data
                dispatch(slice.actions.getClientsSuccess({ clients, total_count }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getClientsSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/clients/summary`, { params, cancelToken });
            if (response.data) {
                dispatch(slice.actions.getClientsSummarySuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoadingSummary());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTopClients(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/clients/top`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getClientsSuccess({
                    summary: {
                        company: 0,
                        individual: 0,
                        total: response.data?.clients?.length || 0
                    },
                    clients: response.data?.clients || [],
                    total_count: response.data?.clients?.length || 0
                }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getClient(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/clients/${id}`);
            if (response.data) {
                dispatch(slice.actions.getClientSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function updateClient(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/clients/${id}`, data);
            if (response.data) {
                dispatch(slice.actions.updateClientsSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function updateClientPartial(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/clients/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response.data) {
                dispatch(slice.actions.updateClientsSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function deleteMultiClients(ids: string[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.post(`/clients/bulk_delete`, { ids });
            if (response.status === 204) {
                return true
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function deleteClient(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.delete(`/clients/${id}`);
            if (response.status === 204) {
                dispatch(slice.actions.deleteClientSuccess(id));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function createClient(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/clients`, data);
            if (response.data) {
                dispatch(slice.actions.createClientSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function uploadFile(cid: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.post(`/clients/${cid}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.data) {
                dispatch(slice.actions.updateClientsSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteAttachment(cid: string, index: number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.delete(`/clients/${cid}/attachments/${index}`);
            if (response.status === 204) {
                dispatch(slice.actions.attachmentDeleted({ index, cid }));
                return true;
            } else {
                dispatch(slice.actions.endLoading())
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function createAdditionalNoteClient(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/clients/${id}/additional_notes`, data);
            if (response) {
                dispatch(slice.actions.updateClientsSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function deleteAdditionalNoteClient(cid: string, nIndex: string | number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/clients/${cid}/additional_notes/${nIndex}`);
            if (response.status === 204) {
                dispatch(slice.actions.deleteNoteClientsSuccess({ cid, nIndex }));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function updateAdditionalNoteClient(cid: string, nIndex: string | number, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/clients/${cid}/additional_notes/${nIndex}`, data);
            if (response.data) {
                dispatch(slice.actions.updateClientsSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function downloadFile(sId: string, fIndex: string | number) {
    return async () => {
        try {
            return await axios.get(`/clients/${sId}/attachments/${fIndex}`, {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export function setClientStore(clients: Client[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setClients(clients));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}