import { createSlice } from '@reduxjs/toolkit';
// @types
import { dispatch } from 'src/redux/store';

const initialState = {
    // serverError: false,
    statusCode: 0,
    message: "",
    status: "",
    timestamp: "",
    violations: []
};

const slice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        // EVENTS
        updateStatus: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        },

        resetState: () => initialState,
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addError(error: any) {
    return async () => {
        dispatch(slice.actions.updateStatus(error));
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}