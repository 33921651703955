import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, useTheme } from '@mui/material';
import LogoImage from 'src/assets/logo-bizyness.svg';
import LogoLightImage from 'src/assets/logo-light-bizyness.svg';
import MinLogoImage from 'src/assets/logo_min.png';
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  isCollapse?: boolean;
}

export default function Logo({ disabledLink = false, sx, isCollapse = false }: Props) {
  const theme = useTheme();
  const logo = (
    <Box
      component="img"
      key={theme.palette.mode}
      src={theme.palette.mode === 'light' ? LogoImage : LogoLightImage}
      sx={{ width: 200, height: 37, cursor: 'pointer', ...sx }}
    />
  );

  const logoMin = (
    <Box
      component="img"
      src={MinLogoImage}
      sx={{ width: 38, height: 38, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{!isCollapse ? logo : logoMin}</>;
  }

  return <RouterLink to="/" style={{ alignSelf: isCollapse ? 'center' : 'auto' }}>{!isCollapse ? logo : logoMin}</RouterLink>;
}
