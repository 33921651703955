import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// config
import { defaultLang } from '../config';
import enLocales from './en';
import frLocales from './fr';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: enLocales },
            fr: { translations: frLocales }
        },
        lng: localStorage.getItem('i18nextLng') ?? defaultLang.value,
        fallbackLng: defaultLang.value,
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        returnNull: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
