import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { QuotesState } from 'src/@types/quotes/quotes';
import { dispatch } from 'src/redux/store';

const initialState: QuotesState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: { summary: null, quotes: [], total_count: 0 }
};

const slice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET
        getQuotesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.quotes = action.payload.quotes;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // GET SINGLE
        getQuoteSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.quotes) state.data.quotes = state.data.quotes.filter(quote => quote.id !== action.payload.id)
            state.data.quotes = state.data.quotes ? [action.payload, ...state.data.quotes] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // DELETE
        deleteQuote: (state, action) => {
            if (state.data.quotes) {
                state.data.quotes = state.data.quotes.filter(quote => quote.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
            state.isLoading = false;
            return state;
        },

        // UPDATE 
        updateQuote: (state, action) => {
            state.data.quotes = state.data.quotes?.map(quote => quote.id === action.payload.id ? action.payload : quote) ?? [action.payload];
            state.isLoading = false;
            return state;
        },

        // CREATE
        createQuote: (state, action) => {
            state.data.quotes = state.data.quotes ? [...state.data.quotes, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // REMOVE ATTACHMENT
        removeAttachment: (state, action) => {
            state.isLoading = false;
            if (state.data.quotes)
                state.data.quotes = state.data.quotes.map(obj => {
                    if (obj.id === action.payload.id) {
                        obj.attachments.splice(action.payload.index, 1);
                        return obj;
                    } return obj;
                })
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // GET SUMMARY
        getQuotesSummarySuccess: (state, action) => {
            state.data.summary = action.payload;
            state.isLoadingSummary = false;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getQuotes(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/quotes`, { params, cancelToken });
            const { quotes = [], total_count = 0 } = response.data;
            dispatch(slice.actions.getQuotesSuccess({ quotes, total_count }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getQuotesSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/quotes/summary`, { params, cancelToken });
            dispatch(slice.actions.getQuotesSummarySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getQuote(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/quotes/${id}`);
            dispatch(slice.actions.getQuoteSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function deleteQuote(quoteId: string) {
    return async () => {
        try {
            const response = await axios.delete(`/quotes/${quoteId}`);
            if (response?.status === 204) {
                dispatch(slice.actions.deleteQuote(quoteId))
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendQuote(quoteId: string, data: any) {
    return async () => {
        try {
            const response = await axios.post(`/quotes/${quoteId}/send`, data);
            if (response?.status === 204) return true;
            else return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function AddQuoteNote(data: any, quoteId: string) {
    return async () => {
        try {
            const response = await axios.post(`/quotes/${quoteId}/annotations`, data);
            if (response?.status === 200) {
                dispatch(slice.actions.updateQuote(response.data))
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createQuote(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/quotes`, data);
            if (response?.data) {
                dispatch(slice.actions.createQuote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateQuote(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/quotes/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateQuote(response.data));
                return response.data
            } else dispatch(slice.actions.endLoading())
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function patchQuote(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/quotes/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updateQuote(response.data));
                return true;
            }
            else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadFile(id: string, data: any, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.post(`/quotes/${id}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200 && update) {
                dispatch(slice.actions.updateQuote(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeFile(id: string, index: number | string, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.delete(`/quotes/${id}/attachments/${index}`);
            if (response.status === 204 && update) {
                dispatch(slice.actions.removeAttachment({ id, index }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function finalizeQuote(id: string, data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/quotes/${id}/finalize`, data);
            if (response?.data) {
                dispatch(slice.actions.updateQuote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function acceptQuote(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/quotes/${id}/accept`, {});
            if (response?.data) {
                dispatch(slice.actions.updateQuote(response.data));
                return true
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function declineQuote(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/quotes/${id}/decline`, {});
            if (response?.data) {
                dispatch(slice.actions.updateQuote(response.data));
                return true
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function deleteBulkQuotes(quoteIds: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/quotes/bulk_delete`, { ids: quoteIds });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}