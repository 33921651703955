import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// components
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
// sections
import { LoginForm } from 'src/sections/auth';
// assets
import { MotivationIllustration } from 'src/assets';
import { PATH_AUTH } from 'src/routes/paths';
import LanguagePopover from 'src/layouts/common/LanguagePopover';
import { useSelector } from 'src/redux/store';
// --------------------------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


export default function Login() {

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const { multi_factor_required } = useSelector(state => state.auth);

    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');

    const { translate } = useLocales();

    const isImpersonate: boolean = location.pathname.includes('/impersonate');

    return (
        <Page title={translate('login.page_title')}>
            <RootStyle>
                <HeaderStyle>
                    <Logo />
                    {!smUp && <LanguagePopover />}
                    {smUp && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: { md: -2 } }}>
                            <LanguagePopover />
                            {(!isImpersonate && !multi_factor_required) &&
                                <Typography sx={{ fontSize: 14, ml: 2 }}>
                                    {translate('login.dont_have_account_label')} {''}
                                    <Link component={RouterLink} to={`${PATH_AUTH.register}?${queryParams.toString()}`} sx={{ fontWeight: 500 }}>
                                        {translate('login.get_started_link')}
                                    </Link>
                                </Typography>}
                        </Box>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            {translate('login.users_say_about_app_heading')}
                        </Typography>
                        <MotivationIllustration
                            sx={{
                                p: 3,
                                width: 420,
                                margin: { xs: 'auto', md: 'inherit' },
                            }}
                        />
                    </SectionStyle>
                )}

                <Container maxWidth="sm">
                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" gutterBottom>
                                    {translate(`login.${multi_factor_required ? 'confirm_heading' : 'form_heading'}`)}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }} variant='body1'>{translate(`login.${multi_factor_required ? 'confirm_subheading' : 'form_subheading'}`)}</Typography>
                            </Box>
                        </Stack>

                        <LoginForm isImpersonate={isImpersonate} verifyCode={multi_factor_required} />

                        {!smUp && !isImpersonate && (
                            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                {translate('login.dont_have_account_label')}{' '}
                                <Link variant="subtitle2" component={RouterLink} to={`${PATH_AUTH.register}?${queryParams.toString()}`}>
                                    {translate('login.get_started_link')}
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
