// yup
import * as Yup from 'yup';
// react
import { useEffect, useState } from 'react';
// react dom
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// notification
import { useSnackbar } from 'notistack';
// @mui
import { Link, Stack, IconButton, InputAdornment, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// components
import Iconify from 'src/components/iconify';
import { FormProvider, RHFTextField2 } from 'src/components/hook-form';
// redux
import { dispatch } from 'src/redux/store';
import { login } from 'src/redux/slices/auth/auth';
import { addNotification } from 'src/redux/slices/notifications/notifications';
// section helper
import { filterObjectByProperties } from 'src/sections/auth/register/RegisterForm';

//----------------------------------------------------


type getPropertiesStartWithProps = {
    paramObject: any,
    subString: string
}
export const getPropertiesStartWith = ({ paramObject, subString }: getPropertiesStartWithProps) => (Object.keys(paramObject).reduce((result: any, key: string) => {
    if (key.startsWith(subString)) {
        result[key] = paramObject[key];
    }
    return result;
}, {}))

type FormValuesProps = {
    email: string;
    password: string;
    afterSubmit?: string;
    account?: string;
    isImpersonate: boolean;
    multi_factor_code: string;
    verifyCode: boolean;
};

interface Props {
    isImpersonate: boolean;
    verifyCode: boolean;
}
export default function LoginForm({ isImpersonate, verifyCode }: Props) {

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const queryParamObject = Object.fromEntries(queryParams.entries());

    let { email, force_login } = queryParamObject;

    const navigate = useNavigate();

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email(translate('login.error.email_must_valid')).required(translate('login.error.email_required')),
        password: Yup.string().required(translate('login.error.password_require')),
        isImpersonate: Yup.boolean(),
        account: Yup.string().when('isImpersonate', {
            is: true,
            then: (schema) => schema.email(translate('login.error.email_must_valid')).required(translate('login.error.account_require'))
        }),
        verifyCode: Yup.boolean(),
        multi_factor_code: Yup.string().when('verifyCode', {
            is: true,
            then: (schema) => schema.required(translate('login.error.code_required'))
        })
    });

    const defaultValues = {
        email: email ?? '',
        password: '',
        account: '',
        multi_factor_code: '',
        isImpersonate: isImpersonate,
        verifyCode: verifyCode
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        watch,
        reset,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = methods;

    useEffect(() => {
        reset({ ...watch(), verifyCode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifyCode]);

    const onSubmit = async (data: FormValuesProps) => {
        try {
            let path: string = '/login';
            let payload: any = {
                email: data.email,
                password: data.password,
                additional_properties: getPropertiesStartWith({ paramObject: queryParamObject, subString: 'prop_' })
            }
            const additional_params = ["subscription_provider"];
            payload = { ...payload, ...filterObjectByProperties(queryParamObject, additional_params) }

            if (isImpersonate) {
                payload.account = data.account;
                path = '/impersonate'
            }

            if (verifyCode) {
                payload.multi_factor_code = data.multi_factor_code
            }
            const loginResponse = await dispatch(login(payload, path));

            // user receive new code on after third attempt, notifying user
            if (loginResponse?.multi_factor_required) {
                setValue('multi_factor_code', '');
                enqueueSnackbar(translate('login.code_sent', { email: data.email }))
            }

            if (!loginResponse) return;

            if (force_login === 'true') navigate(PATH_DASHBOARD.root);

        } catch (error) {
            console.error(error);
            if (!verifyCode) reset();
            if (isMountedRef.current) {
                if (error.status === 401 && verifyCode) {
                    setValue('multi_factor_code', '');
                    enqueueSnackbar(translate('login.error.invalid_code'), { variant: 'error' });
                } else if (error.status === 401) {
                    enqueueSnackbar(translate('login.error.email_password_incorrect'), { variant: 'error' });
                }
            }
        }
    };

    useEffect(() => {
        if (errors.afterSubmit) {
            dispatch(addNotification(errors?.afterSubmit?.message ?? '', 'error'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, dispatch])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {verifyCode ?
                <Box sx={{ mb: 2 }}>
                    <RHFTextField2 autoFocus name="multi_factor_code" label={translate('login.confirm_code_input_label')} onFocus={event => {
                        event.target.select();
                    }} />
                </Box> :
                <Box>
                    <Stack spacing={3}>
                        <RHFTextField2 name="email" label={translate('login.email_address_input_label')} />

                        <RHFTextField2
                            name="password"
                            label={translate('login.password_input_label')}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {isImpersonate &&
                            <RHFTextField2
                                name="account"
                                label={translate('login.account_input_label')}
                            />}

                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <div />
                        {!isImpersonate && <Link component={RouterLink} variant="subtitle2" to={`${PATH_AUTH.resetPassword}?${queryParams.toString()}`} sx={{ fontWeight: 700 }}>
                            {translate('login.forget_password_link')}
                        </Link>}
                    </Stack>
                </Box>}

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                {translate(verifyCode ? 'login.continue_btn' : 'login.connect_btn')}
            </LoadingButton>
        </FormProvider>
    );
}
