export * from './types';

export { default as NavSectionMini } from './mini';
export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function getActive(path: string, pathname: string) {
    let splitted_path = pathname.split('/');
    if (splitted_path.every(str => str === '')) return false;
    if (path === pathname) return true
    return path ? path.includes('/' + splitted_path[1]) : false;
}
