import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { PaymentsState } from 'src/@types/payments/payments';
import { dispatch } from 'src/redux/store';
import { uniqBy } from 'lodash';

const initialState: PaymentsState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: {
        total_count: 0,
        summary: {
            payment: 0,
            refund: 0,
            total: 0
        },
        payments: []
    }
};

const slice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getPaymentsSuccess: (state, action) => {
            state.isLoading = false;
            state.data.payments = action.payload.payments;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // GET SINGLE
        getPaymentSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.payments) state.data.payments = state.data.payments.filter(p => p.id !== action.payload.id)
            state.data.payments = state.data.payments ? uniqBy([action.payload, ...state.data.payments], 'id') : state.data.payments;
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // CREATE NEW PAYMENT
        createPayment: (state, action) => {
            state.data.payments = state.data.payments ? [...state.data.payments, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // UPDATE
        updatePayment: (state, action) => {
            state.data.payments = state.data.payments ? state.data.payments.map(payment => payment.id === action.payload.id ? action.payload : payment) : [action.payload];
            state.isLoading = false;
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // GET EVENTS
        getPaymentsSummarySuccess: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getInvoicePayments(id: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices/${id}/payments`);
            if (response.data) dispatch(slice.actions.getPaymentsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getPayment(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/payments/${id}`);
            dispatch(slice.actions.getPaymentSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCreditNotesPayments(id: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/credit_notes/${id}/payments`);
            if (response.data) dispatch(slice.actions.getPaymentsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCreditNotesPaymentPut(data: any, invoiceId: string, paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/credit_notes/${invoiceId}/payments/${paymentId}`, data);
            if (response?.data) {
                dispatch(slice.actions.updatePayment(response.data));
                return response.data
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function createInvoicePayment(data: any, invoiceId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/invoices/${invoiceId}/payments`, data);
            if (response?.data) {
                dispatch(slice.actions.createPayment(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createPayment(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/payments`, data);
            if (response?.data) {
                dispatch(slice.actions.createPayment(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoicePaymentPartial(data: any, invoiceId: string, paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/invoices/${invoiceId}/payments/${paymentId}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updatePayment(response.data));
                return response.data
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function updateInvoicePaymentPut(data: any, invoiceId: string, paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/invoices/${invoiceId}/payments/${paymentId}`, data);
            if (response?.data) {
                dispatch(slice.actions.updatePayment(response.data));
                return response.data
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoicePayment(data: any, paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/payments/${paymentId}`, data);
            if (response?.data) {
                dispatch(slice.actions.updatePayment(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoicePaymentPatch(data: any, paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/payments/${paymentId}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updatePayment(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteInvoicePayment(paymentId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/payments/${paymentId}`);
            dispatch(slice.actions.endLoading());
            if (response.status === 204) return true
            else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPayments(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/payments`, { params, cancelToken });
            const { payments = [], total_count = 0 } = response.data;
            dispatch(slice.actions.getPaymentsSuccess({ payments, total_count }));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPaymentsSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/payments/summary`, { params, cancelToken });
            dispatch(slice.actions.getPaymentsSummarySuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteBulkPayments(paymentIds: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/payments/bulk_delete`, { ids: paymentIds });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadFile(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(`/payments/${id}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(slice.actions.endLoading())
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}