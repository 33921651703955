import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';
import { AccountingConfigurationState } from 'src/@types/account_configuration/account_configuration';

// ---------------------------------------------------------------------

const initialState: AccountingConfigurationState = {
    isLoading: false,
    error: null,
    data: null,
};

const slice = createSlice({
    name: 'account_configuration',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getAccountingConfigurationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getAccountingConfiguration(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/reports/accounting/configuration`, { params: params });
            dispatch(slice.actions.getAccountingConfigurationSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateAccountingConfiguration(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/reports/accounting/configuration`, data);
            if (response?.data) {
                dispatch(slice.actions.getAccountingConfigurationSuccess(response.data));
                return response.data;
            }
            else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
