// axios
import axios from './axios';


const setSession = (access_token: string | null, type: string = 'Bearer') => {
    if (access_token) {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('access_token_type', type);
        axios.defaults.headers.common.Authorization = `${type} ${access_token}`;

    } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_type');
        delete axios.defaults.headers.common.Authorization;
    }
};

export { setSession };
