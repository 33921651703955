import { useTranslation } from 'react-i18next';
// config
import { allLangs, defaultLang } from '../config';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t: translate } = useTranslation();

    const { onChangeDirectionByLang } = useSettingsContext();

    const langStorage = localStorage.getItem('i18nextLng');

    const currentLang = allLangs.find((_lang) => _lang.value === langStorage) ?? defaultLang;

    const handleChangeLanguage = (newLang: string) => {
        i18n.changeLanguage(newLang);
        onChangeDirectionByLang(newLang);
    };

    return {
        onChangeLang: handleChangeLanguage,
        translate: (text: any, options?: any) => translate(text, options),
        currentLang,
        allLangs,
        i18n
    };
}