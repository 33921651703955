// types
import { Company } from 'src/@types/company/company';
// routes
import {
  PATH_ARTICLES,
  PATH_BANKS,
  PATH_CONTACTS,
  PATH_COSTS,
  PATH_DASHBOARD,
  PATH_INTEGRATIONS,
  PATH_INVOICES,
  PATH_NOTIFICATIONS,
  PATH_QUOTES,
  PATH_REPORTS,
  PATH_SETTINGS
} from "../../routes/paths";
// components
import SvgColor from 'src/components/svg-color';
import { useSelector } from 'src/redux/store';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  banking: getIcon('ic_banking'),
  setting: getIcon('ic_settings'),
  commerce: getIcon('ic_cart'),
  user: getIcon('ic_user'),
  animate: getIcon('ic_animate'),
  analytics: getIcon('ic_analytics'),
  warning: getIcon('ic_warning'),
  layers: getIcon('ic_layers'),
};

// ----------------------------------------------------------------------
type RolePermissions = {
  [key: string]: string[];
};

const noRolePermissions: RolePermissions = {
  always_visible: ['nav_menu.primary.dashboard']
}
// Define permissions and their corresponding localization keys
const rolePermissions: RolePermissions = {
  invoices_read: ['invoices.nav_title', 'recurring_invoices.nav_title', 'credit_notes.nav_title'],
  quotes_read: ['quotes.nav_title'],
  delivery_forms_read: ['delivery_notes.nav_title'],
  payments_read: ['payments.nav_title'],
  expenses_read: ['expenses.nav_title', 'recurring_expenses.nav_title'],
  expense_types_write: ['expense_types.nav_title'],
  clients_read: ['clients.nav_title'],
  suppliers_read: ['suppliers.nav_title'],
  articles_read: ['nav_menu.primary.articles'],
  banks_read: ['banks.nav_title', 'bank_rules.nav_title'],
  integrations_read: ['nav_menu.primary.integrations'],
  activities_write: ['activity_setting.nav_title'],
  report_revenues: ['income_expense_report.nav_title', 'urssaf_report.nav_title'],
  report_taxes: ['vat_report.nav_title', 'goods_report.nav_title'],
  report_accounting: ['accounting_report.nav_title'],
  report_ioss: ['oss_report.nav_title'],
  ...noRolePermissions
};

export function useNavData(company: Company | null, notifications: boolean) {
  const { isRestricted, user } = useSelector(state => state.auth);
  let { roles = [] } = user as any;
  roles = [...roles, 'always_visible']

  const navConfig = [
    // GENERAL
    {
      subheader: '',
      items: [
        {
          title: 'nav_menu.primary.dashboard', path: PATH_DASHBOARD.root,
          icon: ICONS.dashboard
        },
        {
          title: 'nav_menu.primary.invoices',
          path: PATH_INVOICES.root + '~' + PATH_INVOICES.invoice.list + '~' + PATH_INVOICES.recurring.list + '~' + PATH_INVOICES.credit_notes.list + '~' + PATH_INVOICES.payments.list,
          icon: ICONS.invoice,
          children: [
            { title: 'invoices.nav_title', path: PATH_INVOICES.invoice.list },
            { title: 'recurring_invoices.nav_title', path: PATH_INVOICES.recurring.list },
            { title: 'credit_notes.nav_title', path: PATH_INVOICES.credit_notes.list },
            { title: 'payments.nav_title', path: PATH_INVOICES.payments.list },
          ],
        },
        {
          title: 'nav_menu.primary.quotes',
          path: PATH_QUOTES.root + '~' + PATH_QUOTES.delivery_notes.list,
          icon: ICONS.calendar,
          children: [
            { title: 'quotes.nav_title', path: PATH_QUOTES.quote.list },
            { title: 'delivery_notes.nav_title', path: PATH_QUOTES.delivery_notes.list },
          ],
        },
        {
          title: 'nav_menu.primary.costs',
          path: PATH_COSTS.expenses.list + '~' + PATH_COSTS.recurring_expenses.list + '~' + PATH_COSTS.expense_types.list,
          icon: ICONS.commerce,
          children: [
            { title: 'expenses.nav_title', path: PATH_COSTS.expenses.list },
            { title: 'recurring_expenses.nav_title', path: PATH_COSTS.recurring_expenses.list },
            { title: 'expense_types.nav_title', path: PATH_COSTS.expense_types.list },
          ]
        },
        {
          title: 'nav_menu.primary.banks', path: PATH_BANKS.banks.list + '~' + PATH_BANKS.rules.list,
          icon: ICONS.banking,
          children: [
            { title: 'banks.nav_title', path: PATH_BANKS.banks.list },
            { title: 'bank_rules.nav_title', path: PATH_BANKS.rules.list },
          ]
        },
        {
          title: 'nav_menu.primary.contacts',
          path: PATH_CONTACTS.root + '~' + PATH_CONTACTS.client.list + '~' + PATH_CONTACTS.supplier.list,
          icon: ICONS.user,
          children: [
            { title: 'clients.nav_title', path: PATH_CONTACTS.client.list },
            { title: 'suppliers.nav_title', path: PATH_CONTACTS.supplier.list },
          ],
        },
        {
          title: 'nav_menu.primary.articles', path: PATH_ARTICLES.articles.list,
          icon: ICONS.animate
        },
        {
          title: 'nav_menu.primary.integrations', path: PATH_INTEGRATIONS.root,
          icon: ICONS.layers
        },
        {
          title: 'nav_menu.primary.reports', path: PATH_REPORTS.urssaf + '~' + PATH_REPORTS.oss + '~' + PATH_REPORTS.vat + '~' + PATH_REPORTS.goods + '~' + PATH_REPORTS.incomes_expenses + '~' + PATH_REPORTS.accounting,
          icon: ICONS.analytics,
          children: [
            { title: 'income_expense_report.nav_title', path: PATH_REPORTS.incomes_expenses },
            { title: 'vat_report.nav_title', path: PATH_REPORTS.vat },
            { title: 'goods_report.nav_title', path: PATH_REPORTS.goods },
            { title: 'oss_report.nav_title', path: PATH_REPORTS.oss },
            { title: 'accounting_report.nav_title', path: PATH_REPORTS.accounting },
          ],
        },
        {
          title: 'nav_menu.primary.settings',
          path: PATH_SETTINGS.root + '~' + PATH_SETTINGS.company + '~' + PATH_SETTINGS.vat + '~' + PATH_SETTINGS.activity.list,
          icon: ICONS.setting,
          children: [
            { title: 'company_setting.nav_title', path: PATH_SETTINGS.company },
            { title: 'vat_setting.nav_title', path: PATH_SETTINGS.vat },
            { title: 'activity_setting.nav_title', path: PATH_SETTINGS.activity.list },
          ]
        },
      ],
    }
  ]

  // push urssaf reports
  if (company && company.status === 'micro') {
    navConfig[0].items = navConfig[0].items.map(item => {
      if (item.title === 'nav_menu.primary.reports') {
        item.children?.unshift({ title: 'urssaf_report.nav_title', path: PATH_REPORTS.urssaf })
      }
      return item
    })
  }

  // push notifications to navbar
  if (notifications) {
    navConfig[0].items.push({
      title: 'nav_menu.primary.notifications', path: PATH_NOTIFICATIONS.root,
      icon: ICONS.warning
    })
  }

  // remove 
  if (company) {
    navConfig[0].items = navConfig[0].items.map(item => {
      if (item.title === 'nav_menu.primary.reports') {
        // second hand goods report
        if (!company.second_hand_goods_selling) {
          // Find the index of the object in the array
          const indexToRemove = item.children?.findIndex(obj => obj.title === 'goods_report.nav_title');
          // If the object is found in the array, remove it
          if (indexToRemove && indexToRemove !== -1) {
            item.children?.splice(indexToRemove, 1);
          }
        }

        // vat report
        if (!company.vat_liable) {
          // Find the index of the object in the array
          const indexToRemove = item.children?.findIndex(obj => obj.title === 'vat_report.nav_title');
          // If the object is found in the array, remove it
          if (indexToRemove && indexToRemove !== -1) {
            item.children?.splice(indexToRemove, 1);
          }
        }

        // oss/ioss report
        if (!company.oss_liability_date && !company.ioss_liability_date) {
          // Find the index of the object in the array
          const indexToRemove = item.children?.findIndex(obj => obj.title === 'oss_report.nav_title');
          // If the object is found in the array, remove it
          if (indexToRemove && indexToRemove !== -1) {
            item.children?.splice(indexToRemove, 1);
          }
        }
      }
      return item
    })
  }

  if (isRestricted) {
    // Filter the navigation items based on user roles
    navConfig[0].items = navConfig[0].items
      .map(item => {
        if (item.children) {
          // Filter children based on user roles
          item.children = item.children.filter(child => roles.some((role: string) => rolePermissions[role]?.includes(child.title)));
        }

        // Remove items without children if no roles match, except for the dashboard
        if (!item.children?.length && !roles.some((role: string) => rolePermissions[role]?.includes(item.title))) {
          return null;
        }

        return item;
      })
      .filter((item): item is NonNullable<typeof item> => item !== null); // Type assertion to filter out null values
  }

  return navConfig;
}
