import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';
import { DeliveryFormsState } from 'src/@types/delivery_forms/delivery_forms';

const initialState: DeliveryFormsState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: { summary: { draft: { count: 0, amount: '0' }, issued: { count: 0, amount: '0' }, total: { count: 0, amount: '0' } }, delivery_forms: [], total_count: 0 }
};

const slice = createSlice({
    name: 'delivery_forms',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET SINGLE
        getDeliveryNoteSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.delivery_forms) state.data.delivery_forms = state.data.delivery_forms.filter(delivery => delivery.id !== action.payload.id)
            state.data.delivery_forms = state.data.delivery_forms ? [action.payload, ...state.data.delivery_forms] : [action.payload];
            return state;
        },

        // GET EVENTS
        getDeliveryNotesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.delivery_forms = action.payload.delivery_forms;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // UPDATE 
        updateDeliveryNote: (state, action) => {
            state.data.delivery_forms = state.data.delivery_forms?.map(delivery => delivery.id === action.payload.id ? action.payload : delivery) ?? [action.payload];
            state.isLoading = false;
            return state;
        },

        // CREATE
        createDeliveryNote: (state, action) => {
            state.data.delivery_forms = state.data.delivery_forms ? [...state.data.delivery_forms, action.payload] : [action.payload];
            state.data.total_count += state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // DELETE 
        deleteDeliveryNote: (state, action) => {
            if (state.data.delivery_forms) {
                state.data.delivery_forms = state.data.delivery_forms.filter(note => note.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
            state.isLoading = false;
            return state;
        },

        // REMOVE ATTACHMENT
        removeAttachment: (state, action) => {
            state.isLoading = false;
            state.data.delivery_forms = state.data.delivery_forms.map(obj => {
                if (obj.id === action.payload.id) {
                    obj.attachments.splice(action.payload.index, 1);
                    return obj;
                } return obj;
            })
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // SUMMARY
        getDeliveryNotesSummarySuccess: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getDeliveryForm(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/delivery_forms/${id}`);
            dispatch(slice.actions.getDeliveryNoteSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDeliveryForms(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/delivery_forms`, { params, cancelToken });
            const { delivery_forms = [], total_count = 0 } = response.data;
            dispatch(slice.actions.getDeliveryNotesSuccess({ delivery_forms, total_count }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDeliveryFormsSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/delivery_forms/summary`, { params, cancelToken });
            dispatch(slice.actions.getDeliveryNotesSummarySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function deleteBulkDeliverForms(ids: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/delivery_forms/bulk_delete`, { ids });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createDeliveryForm(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/delivery_forms`, data);
            if (response?.data) {
                dispatch(slice.actions.createDeliveryNote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDeliveryForm(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/delivery_forms/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateDeliveryNote(response.data));
                return response.data
            } else dispatch(slice.actions.endLoading())
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadFile(id: string, data: any, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.post(`/delivery_forms/${id}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.status === 200 && update) {
                dispatch(slice.actions.updateDeliveryNote(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeFile(id: string, index: number | string, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.delete(`/delivery_forms/${id}/attachments/${index}`);
            if (response.status === 204 && update) {
                dispatch(slice.actions.removeAttachment({ id, index }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteDeliveryForm(id: string) {
    return async () => {
        try {
            const response = await axios.delete(`/delivery_forms/${id}`);
            if (response?.status === 204) {
                dispatch(slice.actions.deleteDeliveryNote(id))
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function finalizeDeliveryForm(id: any, data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/delivery_forms/${id}/finalize`, data);
            if (response?.data) {
                dispatch(slice.actions.updateDeliveryNote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function sendDeliveryNote(id: string, data: any) {
    return async () => {
        try {
            const response = await axios.post(`/delivery_forms/${id}/send`, data);
            if (response?.status === 204) return true;
            else return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}