// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_COMING_SOON = '/coming-soon';
const ROOTS_MAINTENANCE = '/maintenance';
const ROOTS_INVOICES = '/invoices';
const ROOTS_RECURRING = '/recurring-invoices';
const ROOTS_CREDIT_NOTES = '/credit-notes';
const ROOTS_PAYMENTS = '/payments';
const ROOTS_QUOTES = '/quotes';
const ROOTS_DELIVERY_NOTES = '/delivery-notes';
const ROOTS_CLIENTS = '/clients';
const ROOTS_SUPPLIERS = '/suppliers';
const ROOTS_COMPANY = '/company';
const ROOTS_INTEGRATIONS = '/integrations';
const ROOTS_VAT = '/vat';
const ROOTS_ACTIVITY = '/activity';
const ROOTS_COSTS = '/cost';
const ROOTS_EXPENSE = '/expenses';
const ROOTS_EXPENSE_TYPES = '/expense-types';
const ROOTS_RECURRING_EXPENSE = '/recurring-expense';
const ROOTS_ONBOARDING = '/onboarding';
const ROOTS_ARTICLES = '/articles';
const ROOTS_PROFILE = '/profile';
const ROOTS_BANKS = '/banks';
const ROOTS_RULES = '/rules';
const ROOTS_REPORT = '/report';
const ROOTS_NOTIFICATIONS = '/notifications';
// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/'),
        profile: path(ROOTS_PROFILE, '/')
    }
};

export const PATH_COMING_SOON = {
    root: ROOTS_COMING_SOON,
    general: {
        app: path(ROOTS_COMING_SOON, '/')
    }
};

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    resetPassword: path(ROOTS_AUTH, '/password'),
    register: path(ROOTS_AUTH, '/register'),
    impersonate: path(ROOTS_AUTH, '/impersonate'),
};

export const PATH_MAINTENANCE = {
    root: ROOTS_MAINTENANCE
}

export const PATH_INVOICES = {
    root: ROOTS_INVOICES,
    invoice: {
        list: path(ROOTS_INVOICES, '/list'),
        new: path(ROOTS_INVOICES, '/new'),
        view: (id: string) => path(ROOTS_INVOICES, `/view/${id}`),
        edit: (id: string) => path(ROOTS_INVOICES, `/edit/${id}`),
    },
    recurring: {
        list: path(ROOTS_RECURRING, '/list'),
        new: path(ROOTS_RECURRING, '/new'),
        edit: (id: string) => path(ROOTS_RECURRING, `/edit/${id}`),
    },
    credit_notes: {
        list: path(ROOTS_CREDIT_NOTES, '/list'),
        view: (id: string) => path(ROOTS_CREDIT_NOTES, `/view/${id}`),
        edit: (id: string) => path(ROOTS_CREDIT_NOTES, `/edit/${id}`),
    },
    payments: {
        list: path(ROOTS_PAYMENTS, '/list'),
        new: path(ROOTS_PAYMENTS, '/new'),
        view: (id: string) => path(ROOTS_PAYMENTS, `/view/${id}`),
        edit: (id: string) => path(ROOTS_PAYMENTS, `/edit/${id}`),
    },
}

export const PATH_CONTACTS = {
    root: ROOTS_CLIENTS,
    client: {
        list: path(ROOTS_CLIENTS, '/list'),
        new: path(ROOTS_CLIENTS, '/new'),
        view: (id: string) => path(ROOTS_CLIENTS, `/view/${id}`),
        edit: (id: string) => path(ROOTS_CLIENTS, `/edit/${id}`),
    },
    supplier: {
        list: path(ROOTS_SUPPLIERS, '/list'),
        new: path(ROOTS_SUPPLIERS, '/new'),
        view: (id: string) => path(ROOTS_SUPPLIERS, `/view/${id}`),
        edit: (id: string) => path(ROOTS_SUPPLIERS, `/edit/${id}`),
    }
}

export const PATH_QUOTES = {
    root: ROOTS_QUOTES,
    quote: {
        list: path(ROOTS_QUOTES, '/list'),
        new: path(ROOTS_QUOTES, '/new'),
        view: (id: string) => path(ROOTS_QUOTES, `/view/${id}`),
        edit: (id: string) => path(ROOTS_QUOTES, `/edit/${id}`),
    },
    delivery_notes: {
        list: path(ROOTS_DELIVERY_NOTES, '/list'),
        new: path(ROOTS_DELIVERY_NOTES, '/new'),
        view: (id: string) => path(ROOTS_DELIVERY_NOTES, `/view/${id}`),
        edit: (id: string) => path(ROOTS_DELIVERY_NOTES, `/edit/${id}`),
    }
}


export const PATH_SETTINGS = {
    root: ROOTS_COMPANY,
    company: ROOTS_COMPANY,
    vat: ROOTS_VAT,
    activity: {
        list: path(ROOTS_ACTIVITY, '/list'),
        new: path(ROOTS_ACTIVITY, '/new'),
        edit: (id: string) => path(ROOTS_ACTIVITY, `/edit/${id}`),
    },
}

export const PATH_INTEGRATIONS = {
    root: ROOTS_INTEGRATIONS,
}

export const PATH_ERRORS = {
    server_error: '/500',
    something_wrong: '/400',
    not_found: '/404',
}

export const PATH_COSTS = {
    root: ROOTS_COSTS,
    expenses: {
        list: path(ROOTS_EXPENSE, '/list'),
        new: path(ROOTS_EXPENSE, '/new'),
        edit: (id: string) => path(ROOTS_EXPENSE, `/edit/${id}`),
    },
    expense_types: {
        list: path(ROOTS_EXPENSE_TYPES, '/list')
    },
    recurring_expenses: {
        list: path(ROOTS_RECURRING_EXPENSE, '/list'),
        new: path(ROOTS_RECURRING_EXPENSE, '/new'),
        edit: (id: string) => path(ROOTS_RECURRING_EXPENSE, `/edit/${id}`),
    },
}

export const PATH_BANKS = {
    root: ROOTS_BANKS,
    banks: {
        list: path(ROOTS_BANKS, '/list')
    },
    rules: {
        list: path(ROOTS_RULES, '/list'),
        new: path(ROOTS_RULES, '/new'),
        edit: (id: string) => path(ROOTS_RULES, `/edit/${id}`),
    }
}

export const PATH_ONBOARDING = {
    root: ROOTS_ONBOARDING,
    onboarding: {
        main: path(ROOTS_ONBOARDING, '/'),
    },
}

export const PATH_ARTICLES = {
    root: ROOTS_ARTICLES,
    articles: {
        list: path(ROOTS_ARTICLES, '/list'),
        new: path(ROOTS_ARTICLES, '/new'),
        edit: (id: string) => path(ROOTS_ARTICLES, `/edit/${id}`),
    },
}

export const PATH_REPORTS = {
    root: ROOTS_REPORT,
    urssaf: path('', `${ROOTS_REPORT}-urssaf`),
    oss: path('', `${ROOTS_REPORT}-oss`),
    vat: path('', `${ROOTS_REPORT}-vat`),
    goods: path('', `${ROOTS_REPORT}-goods`),
    incomes_expenses: path('', `${ROOTS_REPORT}-incomes-expenses`),
    accounting: path('', `${ROOTS_REPORT}-accounting`),
}

export const PATH_NOTIFICATIONS = {
    root: ROOTS_NOTIFICATIONS,
}