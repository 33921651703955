import { createSlice } from '@reduxjs/toolkit';
import { ExpensesState } from 'src/@types/expenses/expenses';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';
import { uniqBy } from 'lodash';

const initialState: ExpensesState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: { expenses: [], summary: { total: 0, with_attachment: 0, without_attachment: 0 }, total_count: 0 }
};

const slice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getExpensesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.expenses = action.payload.expenses;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // GET SINGLE
        getExpenseSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.expenses) state.data.expenses = state.data.expenses.filter(p => p.id !== action.payload.id)
            state.data.expenses = state.data.expenses ? uniqBy([action.payload, ...state.data.expenses], 'id') : state.data.expenses;
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // CREATE NEW
        createExpense: (state, action) => {
            state.data.expenses = state.data.expenses ? [...state.data.expenses, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // UPDATE
        updateExpense: (state, action) => {
            state.data.expenses = state.data.expenses ? state.data.expenses.map(expense => expense.id === action.payload.id ? action.payload : expense) : [action.payload];
            state.isLoading = false;
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // GET EVENTS
        getExpensesSummarySuccess: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getExpenses(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/expenses`, { params, cancelToken });
            const { expenses = [], total_count = 0 } = response.data
            dispatch(slice.actions.getExpensesSuccess({ expenses, total_count }));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getExpensesSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/expenses/summary`, { params, cancelToken });
            dispatch(slice.actions.getExpensesSummarySuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function deleteBulkExpenses(expenseIds: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/expenses/bulk_delete`, { ids: expenseIds });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteExpenses(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/expenses/${id}`);
            dispatch(slice.actions.endLoading());
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getExpense(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/expenses/${id}`);
            dispatch(slice.actions.getExpenseSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateExpense(data: any, eid: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/expenses/${eid}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateExpense(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createExpense(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/expenses`, data);
            if (response?.data) {
                dispatch(slice.actions.createExpense(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadFile(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(`/expenses/${id}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            dispatch(slice.actions.endLoading())
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}