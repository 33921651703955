// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
    Radio,
    RadioGroup,
    FormHelperText,
    RadioGroupProps,
    FormControlLabel,
} from '@mui/material';
// lodash
import { omit } from 'lodash';
import { ChangeEvent } from 'react';
// ----------------------------------------------------------------------

type IProps = {
    name: string;
    disabled?: boolean;
    options: {
        label: string;
        value: any;
    }[];
};

type Props = IProps & RadioGroupProps;

export default function RHFRadioGroup({ name, options, disabled = false, ...other }: Props) {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <RadioGroup
                        {...field}
                        row
                        {...omit({ ...other }, ['onChange'])}
                        onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                            let { onChange } = { ...other };
                            if (onChange) {
                                onChange(event, value);
                            }
                            if (["true", "false", true, false].includes(value))
                                setValue(name, value === 'true' ? true : false)
                            else
                                setValue(name, value)
                        }}>
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                disabled={disabled}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>

                    {!!error && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    );
}
