// @mui
import { enUS, frFR, zhTW } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings';
// routes
import { PATH_DASHBOARD } from './routes/paths';
// Util
import { getBrowserLanguage } from './utils/language'
// API
export const HOST_API = process.env.REACT_APP_SERVER_URL || '';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
  pageKey: Date.now().toString(),
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    // label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: 'flagpack:fr',
  },
  {
    // label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  }
];

if (process.env.REACT_APP_ZULU_MODE === 'true') {
  allLangs.push({
    // label: 'CI mode',
    value: 'cimode',
    systemValue: zhTW,
    icon: 'flagpack:fr',
  })
}

const browserLanguage = getBrowserLanguage()
const languageSelected = browserLanguage.includes('fr') ? 0 : 1
export const defaultLang = allLangs[languageSelected ? languageSelected : 0]; // English

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/app'