import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { DocumentState } from 'src/@types/documents/documents';
import { dispatch } from 'src/redux/store';

const initialState: DocumentState = {
    isLoading: false,
    error: null,
    data: { documents: [], total_count: 0 }
};

const slice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET All
        getDocumentsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // GET Single
        getDocumentSuccess: (state, action) => {
            state.isLoading = false;
            state.data.documents = state.data.documents.filter(doc => doc.id !== action.payload.id)
            state.data.documents = [action.payload, ...state.data.documents];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // DELETE Single
        deleteDocumentSuccess: (state, action) => {
            state.isLoading = false;
            state.data.documents = state.data.documents.filter(doc => doc.id !== action.payload);
            state.data.total_count = --state.data.total_count;
            return state;
        },

        // CREATE
        createDocumentSuccess: (state, action) => {
            state.isLoading = false;
            state.data.documents = [...state.data.documents, action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getDocuments() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://virtserver.swaggerhub.com/lpb/bizyness-api/1.0.0/documents`);
            dispatch(slice.actions.getDocumentsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDocument(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://virtserver.swaggerhub.com/lpb/bizyness-api/1.0.0/documents/${id}`);
            dispatch(slice.actions.getDocumentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createDocument(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`https://virtserver.swaggerhub.com/lpb/bizyness-api/1.0.0/documents`, data);
            dispatch(slice.actions.createDocumentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteDocuments(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`https://virtserver.swaggerhub.com/lpb/bizyness-api/1.0.0/documents`);
            dispatch(slice.actions.deleteDocumentSuccess(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
