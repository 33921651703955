import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { InvoicesState } from 'src/@types/invoice/invoice';
import { dispatch } from 'src/redux/store';
import { uniqBy } from 'lodash';

const initialState: InvoicesState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: { summary: null, invoices: [], total_count: 0 }
};

const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // CREATE NEW INVOICE
        createInvoice: (state, action) => {
            state.data.invoices = state.data.invoices ? [...state.data.invoices, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // UPDATE INVOICE
        updateInvoice: (state, action) => {
            if (state.data.invoices) state.data.invoices = state.data.invoices.map(invoice => invoice.id === action.payload.id ? action.payload : invoice);
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET INVOICES
        getInvoicesSuccess: (state, action) => {
            state.data.invoices = action.payload.invoices;
            state.data.total_count = action.payload.total_count;
            state.isLoading = false;
            return state;
        },

        // GET SINGLE INVOICE
        getInvoiceSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.invoices) state.data.invoices = state.data.invoices.filter(invoice => invoice.id !== action.payload.id)
            state.data.invoices = state.data.invoices ? uniqBy([action.payload, ...state.data.invoices], 'id') : state.data.invoices;
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // DELETE INVOICE
        deleteInvoice: (state, action) => {
            if (state.data.invoices) {
                state.data.invoices = state.data.invoices.filter(invoice => invoice.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
            state.isLoading = false;
            return state;
        },

        // REMOVE ATTACHMENT
        removeAttachment: (state, action) => {
            state.isLoading = false;
            if (state.data.invoices)
                state.data.invoices = state.data.invoices.map(obj => {
                    if (obj.id === action.payload.id) {
                        obj.attachments.splice(action.payload.index, 1);
                        return obj;
                    } return obj;
                })
            return state;
        },

        // START LOADING
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // INVOICE SUMMARY
        getInvoicesSummary: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getInvoices(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices`, { params, cancelToken });
            const { invoices = [], total_count = 0 } = response.data;
            dispatch(slice.actions.getInvoicesSuccess({ invoices, total_count }));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoiceSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/invoices/summary`, { params, cancelToken });
            dispatch(slice.actions.getInvoicesSummary(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoice(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices/${id}`);
            dispatch(slice.actions.getInvoiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function createInvoice(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/invoices`, data);
            if (response?.data) {
                dispatch(slice.actions.createInvoice(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoice(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/invoices/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateInvoice(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function patchInvoice(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/invoices/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updateInvoice(response.data));
                return response.data
            }
            else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadFile(id: string, data: any, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.post(`/invoices/${id}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200 && update) {
                dispatch(slice.actions.updateInvoice(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeFile(id: string, index: number | string, update: boolean = false) {
    return async () => {
        try {
            const response = await axios.delete(`/invoices/${id}/attachments/${index}`);
            if (response.status === 204 && update) {
                dispatch(slice.actions.removeAttachment({ id, index }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendInvoice(invoiceId: string, data: any) {
    return async () => {
        try {
            const response = await axios.post(`/invoices/${invoiceId}/send`, data);
            if (response?.status === 204) return true;
            else return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteInvoice(invoiceId: string) {
    return async () => {
        try {
            const response = await axios.delete(`/invoices/${invoiceId}`);
            if (response?.status === 204) {
                dispatch(slice.actions.deleteInvoice(invoiceId))
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteBulkInvoice(invoiceIds: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/invoices/bulk_delete`, { ids: invoiceIds });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function finalizeInvoice(id: any, data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/invoices/${id}/finalize`, data);
            if (response?.data) {
                dispatch(slice.actions.updateInvoice(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}