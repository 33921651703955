import React, { useEffect } from 'react';
// intercom
import { useIntercom } from 'react-use-intercom';
// utils
import { dispatch, useSelector } from 'src/redux/store';
import { loginInitialize } from 'src/redux/slices/auth/auth';
import { getVatRates } from 'src/redux/slices/vat/vat';
import { getCountries } from 'src/redux/slices/countries/countries';
import { getCurrencies } from 'src/redux/slices/currencies/currencies';

export default function AuthProvider() {
    const { update, boot, shutdown } = useIntercom();

    let { isAuthenticated, user, impersonation } = useSelector(state => state.auth)

    useEffect(() => {
        const initialize = async () => dispatch(loginInitialize());

        (async () => {
            try {
                await initialize();
            } catch (error) {
                console.log('initialization Error: ', error)
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            if (!impersonation) {
                boot()
            } else shutdown()

            if (isAuthenticated) {
                if (!impersonation) {
                    update({ email: user?.email || '', name: user?.display_name || '', userHash: user?.transient_attributes?.intercom_hash || '' })
                }

                const promises = [
                    dispatch(getVatRates()),
                    dispatch(getCountries()),
                    dispatch(getCurrencies())
                ]
                await Promise.all(promises);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, impersonation])

    return <React.Fragment />;
}

