import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import { MotionContainer, varBounce } from 'src/components/animate';
// path
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useLocales from 'src/hooks/useLocales';
import Image from 'src/components/image';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
export default function Page400() {
    const { translate } = useLocales();

    return (
        <Page title="Something went wrong" >
            <Container component={MotionContainer}>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <m.div variants={varBounce().in}>
                        <Image
                            alt={translate('common.something_wrong')}
                            src={'/assets/illustrations/character_thinking.png'}
                            sx={{ height: 400, my: { xs: 5, sm: 10 } }}
                        />
                    </m.div>
                    <m.div variants={varBounce().in}>
                        <Typography variant="h3" paragraph>
                            {translate('common.something_wrong')}
                        </Typography>
                    </m.div>
                    <Button size="large" variant="contained" onClick={() => window.location.href = PATH_DASHBOARD.root}>
                        {translate('page_404.go_home')}
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
}
