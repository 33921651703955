import { createSlice } from '@reduxjs/toolkit';
// @types
import { dispatch, store } from 'src/redux/store';
// utils
import axios from 'src/utils/axios';

const initialState = {
    underMaintenance: false
};

const slice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {
        // EVENTS
        updateStatus: (state, action) => {
            state.underMaintenance = action.payload;
            return state;
        },

        resetState: () => initialState,
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateMaintenanceStatus(status: boolean) {

    const { underMaintenance: previousState } = store.getState().maintenance;

    return async () => {
        if (previousState !== status) {
            try {
                dispatch(slice.actions.updateStatus(status));
            } catch (error) {
                console.log(error)
            }

            // CHECK IF SITE IS UNDER MAINTENANCE
            if (status) {
                const interval = setInterval(async () => {
                    try {
                        await axios.get('/users/me');
                        dispatch(slice.actions.updateStatus(false));
                        clearInterval(interval)
                    } catch (error) {
                        if (error.status !== 503) {
                            clearInterval(interval)
                            dispatch(slice.actions.updateStatus(false))
                        }
                    }
                }, 1000)
            }
        }

    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}