import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD, PATH_MAINTENANCE } from '../routes/paths';
// components
import { useSelector } from '../redux/store';

type MaintenanceGuardProps = {
    children: ReactNode;
};

export default function MaintenanceGuard({ children }: MaintenanceGuardProps) {
    const { underMaintenance } = useSelector(state => state.maintenance);
    const { pathname } = useLocation();

    if (underMaintenance && pathname !== PATH_MAINTENANCE.root) {
        return <Navigate to={PATH_MAINTENANCE.root} />;
    }
    if (!underMaintenance && pathname === PATH_MAINTENANCE.root) {
        return <Navigate to={PATH_DASHBOARD.root} />;
    }


    return <>{children}</>;
}
