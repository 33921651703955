import { createSlice } from '@reduxjs/toolkit';
import { Supplier, SuppliersState } from 'src/@types/supplier/supplier';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: SuppliersState = {
    isLoading: false,
    error: null,
    data: { suppliers: [], total_count: 0 }
};

const slice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getSuppliersSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // FETCHED SINGLE
        getSupplierSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.suppliers) state.data.suppliers = state.data.suppliers.filter(supplier => supplier.id !== action.payload.id)
            state.data.suppliers = state.data.suppliers ? [...state.data.suppliers, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // CREATE
        createSupplierSuccess: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = state.data.suppliers ? [...state.data.suppliers, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // UPDATE EVENT
        updateSupplierSuccess: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = state.data.suppliers?.map(supplier => supplier.id === action.payload.id ? action.payload : supplier) ?? state.data.suppliers;
            return state;
        },

        // DELETE
        deleteSupplierSuccess: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = state.data.suppliers?.filter(supplier => supplier.id !== action.payload) ?? state.data.suppliers;
            state.data.total_count = --state.data.total_count;
            return state;
        },

        // DELETE ATTACHMENT
        attachmentDeleted: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = state.data.suppliers?.map(supplier => supplier.id === action.payload.sid ? { ...supplier, attachments: supplier.attachments.filter(a => a !== supplier.attachments[action.payload.index]) } : supplier) ?? state.data.suppliers;
            return state;
        },

        // DELETE NOTE
        deleteNoteSupplierSuccess: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = state.data.suppliers?.map(supplier => {
                if (supplier.id === action.payload.sId) {
                    const additional_notes = [...supplier.additional_notes];
                    additional_notes.splice(action.payload.nIndex, 1);
                    return { ...supplier, additional_notes: additional_notes };
                }
                return supplier;
            }) ?? [];
            return state;
        },

        // set suppliers in store
        setSuppliers: (state, action) => {
            state.isLoading = false;
            state.data.suppliers = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getSuppliers(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/suppliers`, { params, cancelToken });
            if (response.data) {
                dispatch(slice.actions.getSuppliersSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function deleteMultiSuppliers(ids: string[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.post(`/suppliers/bulk_delete`, { ids: ids });
            if (response.status === 204) {
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function getSupplier(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/suppliers/${id}`);
            if (response.data) {
                dispatch(slice.actions.getSupplierSuccess(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createSupplier(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/suppliers`, data);
            if (response.data) {
                dispatch(slice.actions.createSupplierSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function updateSupplierPartial(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/suppliers/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response.data) {
                dispatch(slice.actions.updateSupplierSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function updateSupplier(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/suppliers/${id}`, data);
            if (response.data) {
                dispatch(slice.actions.updateSupplierSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function deleteSupplier(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.delete(`/suppliers/${id}`);
            if (response.status === 204) {
                dispatch(slice.actions.deleteSupplierSuccess(id));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function uploadFile(sid: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.post(`/suppliers/${sid}/attachments`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.data) {
                dispatch(slice.actions.updateSupplierSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteAttachment(sid: string, index: number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.delete(`/suppliers/${sid}/attachments/${index}`);
            if (response.status === 204) {
                dispatch(slice.actions.attachmentDeleted({ index, sid }));
                return true;
            } else {
                dispatch(slice.actions.endLoading())
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}


export function createAdditionalNoteSupplier(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/suppliers/${id}/additional_notes`, data);
            if (response) {
                dispatch(slice.actions.updateSupplierSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function deleteAdditionalNoteSupplier(sId: string, nIndex: string | number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/suppliers/${sId}/additional_notes/${nIndex}`);
            if (response.status === 204) {
                dispatch(slice.actions.deleteNoteSupplierSuccess({ sId, nIndex }));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function updateAdditionalNoteSupplier(sId: string, nIndex: string | number, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/suppliers/${sId}/additional_notes/${nIndex}`, data);
            if (response.data) {
                dispatch(slice.actions.updateSupplierSuccess(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return true;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function downloadFile(sId: string, fIndex: string | number) {
    return async () => {
        try {
            return await axios.get(`/suppliers/${sId}/attachments/${fIndex}`, {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export function getTopSuppliers(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/suppliers/top`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getSuppliersSuccess({ suppliers: response.data?.suppliers || [], total_count: response.data?.suppliers?.length || 0 }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setSupplierStore(suppliers: Supplier[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setSuppliers(suppliers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}