import Box, { BoxProps } from '@mui/material/Box';
import { useSettingsContext } from 'src/components/settings';
import { HEADER } from '../config-layout';
import useResponsive from 'src/hooks/useResponsive';
import { isAccountCancelled } from './header';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const { user } = useSelector(state => state.auth);

  const isAccountSuspended = isAccountCancelled(user);

  const settings = useSettingsContext();

  const isDownLg = (useResponsive('only', 'md') && isAccountSuspended) ? 110 : 0;
  const isDownMd = (useResponsive('only', 'sm') && isAccountSuspended) ? 150 : 0;
  const isDownMd1 = (useResponsive('only', 'xs') && isAccountSuspended) ? 170 : 0;

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const sum = isDownLg + isDownMd + isDownMd1

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: {
            xs: `${HEADER.H_MOBILE + 24 + sum}px`,
            lg: `${HEADER.H_DESKTOP + 80 + (isAccountSuspended ? 110 : 0)}px`,
          },
          pb: {
            xs: `${HEADER.H_MOBILE + 24}px`,
            lg: `${HEADER.H_DESKTOP + 24 + 110}px`,
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: {
          xs: `${HEADER.H_MOBILE + 24 + sum}px`,
          lg: `${HEADER.H_DESKTOP + 40 + (isAccountSuspended ? 80 : 0)}px`,
        },
        py: `${HEADER.H_MOBILE + SPACING}px`,
        pb: {
          xs: `${HEADER.H_MOBILE + 24}px`,
          lg: `${HEADER.H_DESKTOP}px`,
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
