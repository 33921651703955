import { Suspense, lazy, ElementType, useEffect } from 'react';
import { Navigate, useLocation, useRoutes, Outlet } from 'react-router-dom';
// intercom
import { useIntercom } from 'react-use-intercom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
// components
import LoadingScreen from 'src/components/LoadingScreen';
// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
// redux
import { useSelector } from 'src/redux/store';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default function Router() {
  const { update } = useIntercom();
  let { impersonation } = useSelector(state => state.auth)

  const location = useLocation();

  useEffect(() => {
    if (!impersonation)
      update({ lastRequestAt: Date.now() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, impersonation])

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'impersonate',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'password', element: <GuestGuard> <ResetPassword /> </GuestGuard> },
        { path: 'register', element: <GuestGuard> <Register /> </GuestGuard> },
      ],
    },
    // PUBLIC
    {
      path: '/public',
      children: [
        {
          path: 'invoices/:id',
          element: (<PublicInvoice />),
        },
        {
          path: 'quotes/:id',
          element: (<PublicQuote />),
        },
      ],
    },
    // ONBOARDING
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <Onboarding />
        </AuthGuard>
      ),
    },
    // DASHBOARD ROUTES
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'profile', element: <Profile /> },
        {
          path: '/invoices',
          children: [
            { element: <Navigate to="/invoices/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: 'view/:id', element: <ViewInvoice /> },
            { path: 'edit/:id', element: <CreateInvoice /> },
            { path: 'new', element: <CreateInvoice /> },
          ],
        },
        {
          path: '/recurring-invoices',
          children: [
            { element: <Navigate to="/recurring-invoices/list" replace />, index: true },
            { path: 'list', element: <RecurringList /> },
            { path: 'edit/:id', element: <RecurringCreate /> },
            { path: 'new', element: <RecurringCreate /> },

          ],
        },
        {
          path: '/credit-notes',
          children: [
            { element: <Navigate to="/credit-notes/list" replace />, index: true },
            { path: 'list', element: <CreditNotesList /> },
            { path: 'view/:id', element: <CreditNotesView /> },
            { path: 'edit/:id', element: <CreditNotesEdit /> },
          ],
        },
        {
          path: '/payments',
          children: [
            { element: <Navigate to="/payments/list" replace />, index: true },
            { path: 'list', element: <PaymentsList /> },
            { path: 'new', element: <PaymentsCreate /> },
            { path: 'edit/:id', element: <PaymentsCreate /> },
          ],
        },
        {
          path: '/quotes',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <QuoteList /> },
            { path: 'view/:id', element: <QuoteView /> },
            { path: 'edit/:id', element: <QuoteCreate /> },
            { path: 'new', element: <QuoteCreate /> },
          ],
        },
        {
          path: '/delivery-notes',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <DeliveryNoteList /> },
            { path: 'view/:id', element: <DeliveryNoteView /> },
            { path: 'edit/:id', element: <DeliveryNoteCreate /> },
            { path: 'new', element: <DeliveryNoteCreate /> },
          ],
        },
        {
          path: '/expenses',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ExpenseList /> },
            { path: 'edit/:id', element: <ExpenseCreate /> },
            { path: 'new', element: <ExpenseCreate /> },
          ],
        },
        {
          path: '/expense-types',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ExpenseTypesList /> },
          ],
        },
        {
          path: '/recurring-expense',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <RecurringExpenseList /> },
            { path: 'edit/:id', element: <RecurringExpenseCreate /> },
            { path: 'new', element: <RecurringExpenseCreate /> },
          ],
        },
        {
          path: '/banks',
          children: [
            { element: <Navigate to="/banks/list" replace />, index: true },
            { path: 'list', element: <BanksList /> },
          ],
        },
        {
          path: '/rules',
          children: [
            { element: <Navigate to="/rules/list" replace />, index: true },
            { path: 'list', element: <RulesList /> },
            { path: 'new', element: <RulesCreate /> },
            { path: 'edit/:id', element: <RulesCreate /> },
          ],
        },
        {
          path: '/clients',
          children: [
            { element: <Navigate to="/clients/list" replace />, index: true },
            { path: 'list', element: <ClientList /> },
            { path: 'view/:id', element: <ViewClient /> },
            { path: 'edit/:id', element: <CreateClient /> },
            { path: 'new', element: <CreateClient /> },
          ],
        },
        {
          path: '/suppliers',
          children: [
            { element: <Navigate to="/suppliers/list" replace />, index: true },
            { path: 'list', element: <SupplierList /> },
            { path: 'view/:id', element: <SupplierView /> },
            { path: 'edit/:id', element: <SupplierCreate /> },
            { path: 'new', element: <SupplierCreate /> },
          ],
        },
        {
          path: '/articles',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ArticlesList /> },
            { path: 'edit/:id', element: <ArticleCreate /> },
            { path: 'new', element: <ArticleCreate /> },
          ],
        },
        {
          path: '/report-urssaf',
          element: <UrssafReports />
        },
        {
          path: '/report-oss',
          element: <OssReports />
        },
        {
          path: '/report-vat',
          element: <VatReports />
        },
        {
          path: '/report-goods',
          element: <GoodsReports />
        },
        {
          path: '/report-accounting',
          element: <AccountingReports />
        },
        {
          path: '/report-incomes-expenses',
          element: <IncomesExpensesReports />
        },
        { path: 'company', element: <Company /> },
        { path: 'vat', element: <VAT /> },
        { path: 'integrations', element: <IntegrationsList /> },
        {
          path: '/activity',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ActivityList /> },
            { path: 'edit/:id', element: <ActivityCreate /> },
            { path: 'new', element: <ActivityCreate /> },
          ],
        },
        { path: 'notifications', element: <Notifications /> },
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    }
  ]);
}


// AUTHENTICATION
const Login = Loadable(lazy(() => import('src/pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('src/pages/auth/ResetPassword')));
const Register = Loadable(lazy(() => import('src/pages/auth/Register')));

// Dashboard
const DashboardApp = Loadable(lazy(() => import('src/pages/dashboard/Dashboard')));

// PROFILE
const Profile = Loadable(lazy(() => import('src/pages/profile/Profile')));

// Error and Maintenance
const NotFound = Loadable(lazy(() => import('src/pages/Page404')));
const ComingSoon = Loadable(lazy(() => import('src/pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('src/pages/maintenance/Maintenance')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('src/pages/invoice/invoices/InvoiceList')));
const ViewInvoice = Loadable(lazy(() => import('src/pages/invoice/invoices/InvoiceView')));
const CreateInvoice = Loadable(lazy(() => import('src/pages/invoice/invoices/InvoiceCreate')));

// RECURRING INVOICES
const RecurringList = Loadable(lazy(() => import('src/pages/invoice/recurring/RecurringList')));
const RecurringCreate = Loadable(lazy(() => import('src/pages/invoice/recurring/RecurringCreate')));

// CREDIT NOTES
const CreditNotesList = Loadable(lazy(() => import('src/pages/invoice/credit-notes/CreditNotesList')));
const CreditNotesView = Loadable(lazy(() => import('src/pages/invoice/credit-notes/CreditNotesView')));
const CreditNotesEdit = Loadable(lazy(() => import('src/pages/invoice/credit-notes/CreditNotesEdit')));

// PAYMENTS
const PaymentsList = Loadable(lazy(() => import('src/pages/invoice/payments/PaymentsList')));
const PaymentsCreate = Loadable(lazy(() => import('src/pages/invoice/payments/PaymentsCreate')));

//CLIENTS
const ClientList = Loadable(lazy(() => import('src/pages/contacts/client/ClientList')));
const CreateClient = Loadable(lazy(() => import('src/pages/contacts/client/ClientCreate')));
const ViewClient = Loadable(lazy(() => import('src/pages/contacts/client/ClientView')));

// SUPPLIER
const SupplierList = Loadable(lazy(() => import('src/pages/contacts/supplier/SupplierList')));
const SupplierCreate = Loadable(lazy(() => import('src/pages/contacts/supplier/SupplierCreate')));
const SupplierView = Loadable(lazy(() => import('src/pages/contacts/supplier/SupplierView')));

// SETTINGS
const Company = Loadable(lazy(() => import('src/pages/settings/company/CompanyProfile')));
const VAT = Loadable(lazy(() => import('src/pages/settings/vat/Vat')));

// ACTIVITY
const ActivityList = Loadable(lazy(() => import('src/pages/settings/activity/ActivityList')));
const ActivityCreate = Loadable(lazy(() => import('src/pages/settings/activity/ActivityCreate')));

// QUOTE
const QuoteList = Loadable(lazy(() => import('src/pages/quote/quotes/QuoteList')));
const QuoteView = Loadable(lazy(() => import('src/pages/quote/quotes/QuoteView')));
const QuoteCreate = Loadable(lazy(() => import('src/pages/quote/quotes/QuoteCreate')));

// DELIVERY NOTES
const DeliveryNoteList = Loadable(lazy(() => import('src/pages/quote/delivery_notes/DeliveryNotesList')));
const DeliveryNoteCreate = Loadable(lazy(() => import('src/pages/quote/delivery_notes/DeliveryNoteCreate')));
const DeliveryNoteView = Loadable(lazy(() => import('src/pages/quote/delivery_notes/DeliveryNoteView')));

// EXPENSE
const ExpenseList = Loadable(lazy(() => import('src/pages/cost/expense/ExpenseList')));
const ExpenseCreate = Loadable(lazy(() => import('src/pages/cost/expense/ExpenseCreate')));

// RECURRING EXPENSES
const RecurringExpenseList = Loadable(lazy(() => import('src/pages/cost/recurring/RecurringList')));
const RecurringExpenseCreate = Loadable(lazy(() => import('src/pages/cost/recurring/RecurringCreate')));

//ONBOARDING
const Onboarding = Loadable(lazy(() => import('src/pages/onboarding/Onboarding')));

// ARTICLES
const ArticlesList = Loadable(lazy(() => import('src/pages/articles/ArticlesList')));
const ArticleCreate = Loadable(lazy(() => import('src/pages/articles/ArticleCreate')));

// BANKS
const BanksList = Loadable(lazy(() => import('src/pages/bank/banks/BanksList')));

// RULES
const RulesList = Loadable(lazy(() => import('src/pages/bank/rules/RulesList')));
const RulesCreate = Loadable(lazy(() => import('src/pages/bank/rules/RulesCreate')));

// EXPENSE TYPES
const ExpenseTypesList = Loadable(lazy(() => import('src/pages/cost/expense-types/ExpenseTypesList')));

// REPORTS
const UrssafReports = Loadable(lazy(() => import('src/pages/reports/urssaf/Urssaf')));
const OssReports = Loadable(lazy(() => import('src/pages/reports/oss/Oss')));
const VatReports = Loadable(lazy(() => import('src/pages/reports/vat/Vat')));
const GoodsReports = Loadable(lazy(() => import('src/pages/reports/goods/Goods')));
const IncomesExpensesReports = Loadable(lazy(() => import('src/pages/reports/incomes_expenses/IncomesExpenses')));
const AccountingReports = Loadable(lazy(() => import('src/pages/reports/accounting/Accounting')));

// NOTIFICATIONS
const Notifications = Loadable(lazy(() => import('src/pages/notifications/Notifications')));

// INTEGRATIONS
const IntegrationsList = Loadable(lazy(() => import('src/pages/integrations/IntegrationList')));

// PUBLIC ROUTES
const PublicInvoice = Loadable(lazy(() => import('src/pages/public/invoice/Invoice')));
const PublicQuote = Loadable(lazy(() => import('src/pages/public/quote/quote')));
