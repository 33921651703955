import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { ArticlesState } from 'src/@types/articles/articles';
import { dispatch } from 'src/redux/store';

const initialState: ArticlesState = {
    isLoading: false,
    error: null,
    data: { articles: [], total_count: 0 }
};

const slice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getArticlesSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // GET EVENTS
        getArticleSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.articles) state.data.articles = state.data.articles.filter(article => article.id !== action.payload.id)
            state.data.articles = state.data.articles ? [...state.data.articles, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // CREATE NEW
        createArticlesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.articles = state.data.articles ? [...state.data.articles, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // UPDATE EXISTING
        updateArticlesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.articles = state.data.articles ? state.data.articles.map(article => article.id === action.payload.id ? action.payload : article) : [action.payload];
            return state;
        },

        // DELETE 
        deleteArticleSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.articles) {
                state.data.articles = state.data.articles.filter(article => article.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getArticles(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/articles`, { params, cancelToken });
            dispatch(slice.actions.getArticlesSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getArticle(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/articles/${id}`);
            if (response?.data) {
                dispatch(slice.actions.getArticleSuccess(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTopArticles(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/articles/top`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getArticlesSuccess({ articles: response.data?.articles || [], total_count: response.data?.articles?.length || 0 }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function createArticle(article: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/articles`, article);
            dispatch(slice.actions.createArticlesSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateArticle(id: string, article: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/articles/${id}`, article);
            dispatch(slice.actions.updateArticlesSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteArticle(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.delete(`/articles/${id}`);
            if (response.status === 204) {
                dispatch(slice.actions.deleteArticleSuccess(id));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function deleteMultiArticles(ids: string[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let response = await axios.post(`/articles/bulk_delete`, { ids: ids });
            if (response.status === 204) {
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}