import { createSlice } from '@reduxjs/toolkit';
import { RulesState } from 'src/@types/banks_rules/banks_rules';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: RulesState = {
    isLoading: false,
    error: null,
    data: {
        bank_rules: [],
        total_count: 0
    }
};

const slice = createSlice({
    name: 'banks_rules',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getRulesSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // DELETE
        deleteBankRuleSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.bank_rules) {
                state.data.bank_rules = state.data.bank_rules.filter(rule => rule.id !== action.payload);
                state.data.total_count = --state.data.total_count;
            }
        },

        // UPDATE EXISTING
        updateRuleSuccess: (state, action) => {
            state.isLoading = false;
            state.data.bank_rules = state.data.bank_rules ? state.data.bank_rules.map(rule => rule.id === action.payload.id ? action.payload : rule) : [action.payload];
            return state;
        },

        // GET SINGLE
        getBankRuleSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.bank_rules) state.data.bank_rules = state.data.bank_rules.filter(rule => rule.id !== action.payload.id)
            state.data.bank_rules = state.data.bank_rules ? [...state.data.bank_rules, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // CREATE NEW
        createBankRuleSuccess: (state, action) => {
            state.isLoading = false;
            state.data.bank_rules = state.data.bank_rules ? [...state.data.bank_rules, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getBankRules(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/bank_rules`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getRulesSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function activateBankRule(id: any, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/bank_rules/${id}/activate`, data);
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deactivateBankRule(id: any, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/bank_rules/${id}/deactivate`, data);
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function deleteBankRule(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/bank_rules/${id}`);
            dispatch(slice.actions.deleteBankRuleSuccess(id));
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function updateBankRule(id: string, rule: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/bank_rules/${id}`, rule);
            dispatch(slice.actions.updateRuleSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getBankRule(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/bank_rules/${id}`);
            if (response?.data) {
                dispatch(slice.actions.getBankRuleSuccess(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            };
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBankRule(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/bank_rules`, data);
            dispatch(slice.actions.createBankRuleSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
