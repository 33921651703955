// @mui
import { Typography, Dialog, Button, DialogTitle, DialogActions, DialogContent, useTheme } from '@mui/material';
//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  icon,
  open,
  onClose,
  cancelText,
  cancel = true,
  escapeKeyDown = false,
  backdropClick = true,
  ...other
}: ConfirmDialogProps) {
  const theme = useTheme();

  const closeDialog = (_: any, reason: string) => {
    if (reason && reason === 'backdropClick' && !backdropClick) onClose();
    else if (reason && reason === 'escapeKeyDown' && !escapeKeyDown) onClose();
    else return;
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={closeDialog} {...other} sx={{ textAlign: 'center' }} disableEscapeKeyDown={escapeKeyDown}>
      {icon}
      <DialogTitle sx={{ alignSelf: 'center', pt: .75, mt: .75, pb: 0 }} variant='h5'>{title}</DialogTitle>

      {content && <DialogContent sx={{ alignSelf: 'center', p: .75, pl: 3.5, pr: 3.5, m: .75 }}><Typography variant='body2' color={theme.palette.grey[600]}> {content} </Typography></DialogContent>}

      <DialogActions style={{ alignSelf: 'center' }}>
        {cancel && <Button variant="outlined" color="inherit" onClick={onClose}>
          {cancelText ?? "Cancel"}
        </Button>}
        {action}

      </DialogActions>
    </Dialog>
  );
}
