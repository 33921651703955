import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { BankTransactionState } from 'src/@types/banks/transaction';
import { dispatch } from 'src/redux/store';

const initialState: BankTransactionState = {
    isLoading: false,
    error: null,
    data: {
        total_count: 0,
        summary: {
            associated: 0,
            ignored: 0,
            pending: 0,
            total: 0
        },
        transactions: []
    }
};

const slice = createSlice({
    name: 'bank_transactions',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getTransactionsSuccess: (state, action) => {
            state.isLoading = false;
            state.data.transactions = action.payload.transactions;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // GET EVENTS
        getTransactionSummarySuccess: (state, action) => {
            state.isLoading = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getBankTransactions(id: string, params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/banks/${id}/transactions`, { params: params });
            const { transactions = [], total_count = 0 } = response.data;
            dispatch(slice.actions.getTransactionsSuccess({ transactions, total_count }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBankTransactionsSummary(id: string, params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/banks/${id}/transactions/summary`, { params: params });
            dispatch(slice.actions.getTransactionSummarySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}