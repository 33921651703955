import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { CreditNotesState } from 'src/@types/credit_notes/credit_notes';
import { dispatch } from 'src/redux/store';

const initialState: CreditNotesState = {
    isLoading: false,
    isLoadingSummary: false,
    error: null,
    data: { total_count: 0, credit_notes: [], summary: { total: { count: 0, amount: '0' }, draft: { count: 0, amount: '0' }, issued: { count: 0, amount: '0' } } }
};

const slice = createSlice({
    name: 'credit_notes',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.isLoadingSummary = false;
            state.error = action.payload;
            return state;
        },

        // GET
        getCreditNotesSuccess: (state, action) => {
            state.isLoading = false;
            state.data.credit_notes = action.payload.credit_notes;
            state.data.total_count = action.payload.total_count;
            return state;
        },

        // UPDATE
        updateCreditNote: (state, action) => {
            if (state.data.credit_notes) state.data.credit_notes = state.data.credit_notes.map(credit => credit.id === action.payload.id ? action.payload : credit);
            state.isLoading = false;
            return state;
        },

        // GET SINGLE
        getSingleCreditNoteSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.credit_notes) state.data.credit_notes = state.data.credit_notes.filter(credit_note => credit_note.id !== action.payload.id)
            state.data.credit_notes = state.data.credit_notes ? [...state.data.credit_notes, action.payload] : state.data.credit_notes;
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // START LOADING Summary
        startLoadingSummary: (state) => {
            state.isLoadingSummary = true;
            return state;
        },

        // END LOADING Summary
        endLoadingSummary: (state) => {
            state.isLoadingSummary = false;
            return state;
        },

        // GET SUMMARY
        getCreditNoteSummary: (state, action) => {
            state.isLoadingSummary = false;
            state.data.summary = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getInvoiceCreditNotes(invoiceId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices/${invoiceId}/credit_notes`);
            if (response.data) {
                dispatch(slice.actions.getCreditNotesSuccess(response.data ?? { total_count: 0, credit_notes: [] }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCreditNotes(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/credit_notes`, { params, cancelToken });
            if (response.data) {
                const { credit_notes = [], total_count = 0 } = response.data;

                dispatch(slice.actions.getCreditNotesSuccess({ total_count, credit_notes }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCreditNotesSummary(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoadingSummary());
        try {
            const response = await axios.get(`/credit_notes/summary`, { params, cancelToken });
            if (response.data) {
                dispatch(slice.actions.getCreditNoteSummary(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoadingSummary());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCreditNoteById(id: string, params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/credit_notes/${id}`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getSingleCreditNoteSuccess(response.data ?? { total_count: 0, credit_notes: [] }));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendCreditNote(id: string, data: any) {
    return async () => {
        try {
            const response = await axios.post(`/credit_notes/${id}/send`, data);
            if (response?.status === 204) return true;
            else return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteBulkCreditNotes(noteIds: string[]) {
    return async () => {
        try {
            const response = await axios.post(`/credit_notes/bulk_delete`, { ids: noteIds });
            if (response?.status === 204) {
                return true
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function finalizeCreditNote(id: any, data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/credit_notes/${id}/finalize`, data);
            if (response?.data) {
                dispatch(slice.actions.updateCreditNote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCreditNotePatch(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/credit_notes/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updateCreditNote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCreditNotePut(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/credit_notes/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateCreditNote(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}