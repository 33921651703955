// react
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// notification
import { useSnackbar } from 'notistack';
// redux
import { useSelector, dispatch } from 'src/redux/store';
import { removeNotification } from 'src/redux/slices/notifications/notifications';
import { getNotifications } from 'src/redux/slices/notifications/app_notifications';
// component
import ConfirmDialog from 'src/components/warning-dialog/ConfirmDialog';
import Iconify from 'src/components/iconify';
// hooks
import useToggle from 'src/hooks/useToggle';
import useLocales from 'src/hooks/useLocales';
import { Button, useTheme } from '@mui/material';
// path
import { PATH_AUTH, PATH_NOTIFICATIONS } from 'src/routes/paths';
import { onboarding_paths } from 'src/guards/OnboardingGuard';

//---------------------------------------------------------------------


export default function Notification() {
    const theme = useTheme();

    const navigate = useNavigate();

    const { translate } = useLocales();

    const notifications = useSelector(state => state.notifications);

    const { data: company } = useSelector(state => state.company);

    const { data: { notifications: app_notifications } } = useSelector(state => state.app_notifications);

    const [displayed, setDisplayed] = useState(false);

    const { isAuthenticated, user } = useSelector(state => state.auth);

    const { enqueueSnackbar } = useSnackbar();

    const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

    const isAuthRoutes = [PATH_AUTH.login, PATH_AUTH.impersonate, PATH_AUTH.register, PATH_AUTH.resetPassword, '/'].includes(window.location.pathname)

    useEffect(() => {
        notifications.forEach(obj => {
            enqueueSnackbar(obj.i18key ? translate(obj.i18key) : obj.message, {
                variant: obj.type,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                }
            });
            dispatch(removeNotification(obj.id))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications, enqueueSnackbar])

    useEffect(() => {
        let intervalId: any;

        if (!isAuthenticated || (company && !company?.status) || onboarding_paths.includes(window.location.pathname)) return;

        const fetchData = () => {
            dispatch(getNotifications({ offset: 0, limit: 250, sort: '-date' }));
        };

        fetchData(); // Initial call when isAuthenticated is true

        intervalId = setInterval(fetchData, 60000 * 5); // Periodic call every 5 minutes

        return () => {
            // This function will be called when the component unmounts or when the dependency [isAuthenticated] changes.
            clearInterval(intervalId); // Clear the interval to prevent further dispatching when the component unmounts or the user logs out.
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, dispatch, user?.id]);

    useEffect(() => {
        if (app_notifications?.length && !displayed && !isAuthRoutes) {
            if (app_notifications.some(app_notification => app_notification.is_new) && !window.location.pathname.includes('/notifications')) {
                onOpenTo();
                setDisplayed(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app_notifications, isAuthRoutes]);

    const seeMore = () => {
        onCloseTo();
        navigate(PATH_NOTIFICATIONS.root);
    }

    return (<ConfirmDialog
        open={openTo && !isAuthRoutes}
        onClose={onCloseTo}
        title={translate('notification.unread_popup.heading')}
        content={translate('notification.unread_popup.description')}
        cancelText={translate('notification.unread_popup.see_more_btn')}
        cancel={false}
        icon={<Iconify sx={{ alignSelf: 'center', mt: 3 }} color={theme.palette.error.main} icon={'eva:alert-triangle-fill'} width={40} height={40} />}
        action={
            <Button
                variant="contained"
                color="error"
                onClick={seeMore}
                sx={{ color: 'white' }}
            >
                {translate('notification.unread_popup.see_more_btn')}
            </Button>
        }
    />);
}
