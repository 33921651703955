import * as React from 'react'
import useLocales from 'src/hooks/useLocales';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

//-----------------------------------------------


type props = {
    translate: (text: any, options?: any) => string
}
function Version({ translate }: props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${window.location.origin}/application.json`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.version !== process.env.REACT_APP_VERSION) {
                        enqueueSnackbar(translate('version.version'), {
                            variant: 'warning',
                            action: (key) => (
                                <>
                                    <Button onClick={() => reloadPage()}>
                                        {translate('version.reload')}
                                    </Button>
                                </>
                            ),
                            persist: true,
                        });
                    }
                })
                .catch((error) => console.log(error));
        }, 600000);

        return () => clearInterval(interval);
    }, [enqueueSnackbar, closeSnackbar, translate]);

    const reloadPage = () => {
        window.location.reload();
    };

    return null;
}

export default function ParentComponent() {
    const { translate } = useLocales();

    return <Version translate={translate} />;
}
