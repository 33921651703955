import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { CompanyState } from 'src/@types/company/company';
import { dispatch } from 'src/redux/store';

const initialState: CompanyState = {
    isLoading: false,
    error: null,
    data: null
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getCompany: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // UPDATE
        updateCompany: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getCompany() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/companies/my`);
            dispatch(slice.actions.getCompany(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function updateCompanyPartial(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/companies/my`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            dispatch(slice.actions.updateCompany(response.data));
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}