import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { CurrenciesState } from 'src/@types/currencies/currencies';
import { dispatch } from 'src/redux/store';

const initialState: CurrenciesState = {
    isLoading: false,
    error: null,
    data: { currencies: [] }
};

const slice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getCurrenciesSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getCurrencies() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/currencies`);
            dispatch(slice.actions.getCurrenciesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}