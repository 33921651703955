import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { RevenueReportState } from 'src/@types/dashboard/revenue_report';
import { dispatch } from 'src/redux/store';

const initialState: RevenueReportState = {
    isLoading: false,
    error: null,
    data: { summary: { expenses: '', incomes: '', margin: '', variation: '' }, segments: [], currency: 'EUR' }
};

const slice = createSlice({
    name: 'yearly_revenue_report',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;

        },

        // GET EVENTS
        getYearlyRevenueSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getYearlyRevenue(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/reports/revenues`, { params });
            dispatch(slice.actions.getYearlyRevenueSuccess(response.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}