import { createSlice } from '@reduxjs/toolkit';
import { BanksState } from 'src/@types/banks/bank';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: BanksState = {
    isLoading: false,
    error: null,
    data: {
        banks: [],
        total_count: 0
    }
};

const slice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getBanksSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // GET SINGLE
        getBankSuccess: (state, action) => {
            state.isLoading = false;
            state.data.banks = state.data.banks ? [...state.data.banks, action.payload] : [action.payload];
            state.data.total_count += state.data.total_count;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getBanks(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/banks`, { params: params });
            if (response.data) {
                dispatch(slice.actions.getBanksSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBank(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/banks/${id}`);
            if (response.data) {
                dispatch(slice.actions.getBankSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
