import numeral from "numeral";
import "numeral/locales/fr";
import "numeral/locales/en-gb"
// -------------------------------
export type NumeralLanguagePresets = 'fr' | 'en';

const languagePresets = ['fr', 'en-gb'];
const frenchPreset = languagePresets[0];
const englishPreset = languagePresets[1]
export default function getLanguagePresets(presetsKey: NumeralLanguagePresets) {
    return {
        fr: frenchPreset,
        en: englishPreset
    }[presetsKey];
}


export function formatCurrency(number?: number | string, currentLang: string = 'fr', decimals: number = 2, currency: string = 'EUR') {
    const languageKeys = ['en-US', 'fr-FR'];
    const currencyFormatter = new Intl.NumberFormat(languageKeys.find(l => l.includes(currentLang)) ?? currentLang, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: decimals
    });
    return currencyFormatter.format(Number(number) || 0).replace(/(?=\s)[^\r\n\t]/g, ' ')
}

export function formatNumber(number?: number | string, fmt: string = '0.00') {
    if (fmt === 'none') return number
    return numeral(`${number}`.replace(',', '.')).format(fmt)
}

export function fPercent(number: number, format: string = '0.0%') {
    return numeral(number / 100).format(format);
}

export function percentageOf(paid: number, total: number, format: string = '0.0%') {
    return numeral((paid / total)).format(format);
}

export function fNumber(number: string | number) {
    return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
    return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
    return numeral(number).format('0.0 b');
}

export function formatNumberWithoutRounding(number: string | number) {
    if (!number) return 0;
    return Number(number?.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)?.[0]) ?? 0
}