import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import { FormProvider, RHFTextField2 } from '../../../components/hook-form';
//hooks
import useLocales from '../../../hooks/useLocales';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// redux
import { resetPassword } from 'src/redux/slices/auth/auth';
import { useDispatch } from 'src/redux/store'
import { addNotification } from 'src/redux/slices/notifications/notifications';

// --------------------------------------------------------------------------------


type FormValuesProps = {
    email: string;
    afterSubmit?: string;
};

export default function ResetPasswordForm() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const navigate = useNavigate();

    const { translate } = useLocales();

    const dispatch = useDispatch()

    const isMountedRef = useIsMountedRef();

    const ResetPasswordSchema = Yup.object().shape({
        email: Yup.string().email(translate('forget_password.error.email_must_valid')).required(translate('forget_password.error.email_required')),
    });

    const defaultValues = {
        email: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(ResetPasswordSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            await dispatch(resetPassword(data.email))

            sessionStorage.setItem('email-recovery', data.email);

            navigate(`${PATH_AUTH.login}?${queryParams.toString()}`);
            dispatch(addNotification(translate('forget_password.password_sent_alert'), 'success'))
        } catch (error) {
            reset()
            console.error(error);
            if (isMountedRef.current) {
                setError('afterSubmit', { ...error, message: error.message });
            }
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && <Alert severity="error" sx={{ overflowWrap: 'break-word' }}>{errors.afterSubmit.message}</Alert>}

                <RHFTextField2 name="email" label={translate('forget_password.email_address_input_label')} />

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    {translate('forget_password.reset_btn')}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
