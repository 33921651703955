import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { RecurringExpenseState } from 'src/@types/recurring/recurring';
import { dispatch } from 'src/redux/store';

const initialState: RecurringExpenseState = {
    isLoading: false,
    error: null,
    data: { recurring_expenses: [], total_count: 0 }
};

const slice = createSlice({
    name: 'recurring_expense',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // GET RECURRING
        getRecurringSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET SINGLE
        getSingleRecurringSuccess: (state, action) => {
            state.isLoading = false;
            if (state.data.recurring_expenses) state.data.recurring_expenses = state.data.recurring_expenses.filter(recurring => recurring.id !== action.payload.id)
            state.data.recurring_expenses = state.data.recurring_expenses ? [...state.data.recurring_expenses, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // UPDATE 
        updateRecurring: (state, action) => {
            state.data.recurring_expenses = state.data.recurring_expenses?.map(recurring => recurring.id === action.payload.id ? action.payload : recurring) ?? [action.payload];
            state.isLoading = false;
            return state;
        },

        // CREATE NEW INVOICE
        createRecurring: (state, action) => {
            state.data.recurring_expenses = state.data.recurring_expenses ? [...state.data.recurring_expenses, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            state.isLoading = false;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getRecurringExpenses(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/recurring_expenses`, { params: params });
            dispatch(slice.actions.getRecurringSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function startLoading() {
    return async () => {
        dispatch(slice.actions.startLoading())
    }
}

export function endLoading() {
    return async () => {
        dispatch(slice.actions.endLoading())
    }
}

export function deleteRecurringExpense(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/recurring_expenses/${id}`);
            if (response.status === 204) {
                dispatch(getRecurringExpenses({ limit: 250, offset: 0 }))
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false
        }
    };
}

export function activateRecurringExpense(id: any, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/recurring_expenses/${id}/activate`, data);
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deactivateRecurringExpense(id: any, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/recurring_expenses/${id}/deactivate`, data);
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getSingleRecurringExpense(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/recurring_expenses/${id}`);
            dispatch(slice.actions.getSingleRecurringSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function patchRecurringExpense(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/recurring_expenses/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response?.data) {
                dispatch(slice.actions.updateRecurring(response.data));
                return response.data;
            }
            else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateRecurringExpense(id: string, data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/recurring_expenses/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateRecurring(response.data));
                return response.data;
            }
            else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createRecurringExpense(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/recurring_expenses`, data);
            if (response?.data) {
                dispatch(slice.actions.createRecurring(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading())
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}