import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash'
// @types
import { dispatch } from 'src/redux/store';
// @type
import { NotificationType } from 'src/@types/notifications/notifications';

const initialState: NotificationType[] = [];

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        // EVENTS
        addNotification: (state, action) => {
            state.push(action.payload);
            return state;
        },

        removeNotification: (state, action) => {
            state = state.filter(obj => obj.id !== action.payload);
            return state;
        },

        resetState: () => initialState,
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addNotification(message: string, type: string, i18key: string = "") {
    return async () => {
        if (!isEmpty(message.trim()) && !isEmpty(type.trim())) {
            const notification = {
                id: Date.now(),
                message: message,
                type: type,
                i18key
            }
            try {
                dispatch(slice.actions.addNotification(notification));
            } catch (error) {
                console.log(error)
            }
        }
    };
}

export function removeNotification(id: number) {
    return async () => {

        try {
            dispatch(slice.actions.removeNotification(id));
        } catch (error) {
            console.log(error)
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
