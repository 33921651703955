// react
import React from 'react';
// pages
import Page400 from '../Page400';
import Catch from "./ErrorCatch"
// --------------------------------------------------------------------------------------


type Props = {
    children: React.ReactNode
}

export const ErrorBoundary = Catch(function ErrorBoundary(props: Props, error?: Error) {
    if (error) {
        return (<Page400 />)
    } else {
        return <React.Fragment>{props.children}</React.Fragment>
    }
})

