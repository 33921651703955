import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { ApplicableTaxesState } from 'src/@types/vat/vat';
import { dispatch } from 'src/redux/store';

const initialState: ApplicableTaxesState = {
    isLoading: false,
    error: null,
    data: null
};

const slice = createSlice({
    name: 'applicable_taxes',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getApplicableTaxesSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getApplicableTaxes(data: any, url: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(url, data);
            dispatch(slice.actions.getApplicableTaxesSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}