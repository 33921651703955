import { createSlice } from '@reduxjs/toolkit';
// lodash
import { sortBy } from 'lodash';
// utils
import axios from 'src/utils/axios';
// @types
import { ActivitiesState, Activity } from 'src/@types/activities/activities';
import { dispatch } from 'src/redux/store';

const initialState: ActivitiesState = {
    isLoading: false,
    error: null,
    data: { total_count: 0, activities: [] }
};

const slice = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getActivitiesSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // GET EVENTS
        getActivitySuccess: (state, action) => {
            state.isLoading = false;
            state.data.activities = state.data.activities ? [...state.data.activities, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // UPDATE
        updateActivity: (state, action) => {
            state.isLoading = false;
            state.data.activities = state.data.activities ? state.data.activities.map(activity => action.payload.id === activity.id ? action.payload : activity) : [action.payload];
            return state;
        },

        updateActivityLogo: (state, action) => {
            state.isLoading = false;
            if (state.data.activities) {
                state.data.activities = state.data.activities.map(activity => {
                    if
                        (activity.id === action.payload.id) {
                        return { ...activity, logo: action.payload.logo }
                    } else return activity
                })
            }
        },

        // CREATE NEW
        createActivitySuccess: (state, action) => {
            state.isLoading = false;
            state.data.activities = state.data.activities ? [...state.data.activities, action.payload] : [action.payload];
            state.data.total_count = ++state.data.total_count;
            return state;
        },

        // set activity in store
        setActivities: (state, action) => {
            state.isLoading = false;
            state.data.activities = action.payload;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getActivities(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/activities`, { params: params });
            if (response.data?.activities) {
                response.data.activities = sortBy(response.data.activities, 'name');
            }
            dispatch(slice.actions.getActivitiesSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getActivity(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/activities/${id}`);
            if (response.data) {
                dispatch(slice.actions.getActivitySuccess(response.data));
                return response.data
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateActivityPartial(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/activities/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response.data) {
                dispatch(slice.actions.updateActivity(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function createActivity(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/activities`, data);
            dispatch(slice.actions.createActivitySuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateActivity(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/activities/${id}`, data);
            if (response?.data) {
                dispatch(slice.actions.updateActivity(response.data));
                return response.data
            } else return null
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteActivity(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/activities/${id}`);
            if (response.status === 204) {
                // refresh activities
                let { activities } = await dispatch(getActivities({ offset: 0, limit: 250 }));
                if (!activities.some((activity: Activity) => activity.id === id))
                    return true;
                else return false
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function uploadLogo(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/activities/${id}/logo`, data);
            dispatch(slice.actions.endLoading());
            if (response.data) {
                return response.data;
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadAttachments(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/activities/${id}/attachments`, data);
            dispatch(slice.actions.endLoading());
            if (response.data) {
                return response.data;
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadSignature(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/activities/${id}/signature`, data);
            dispatch(slice.actions.endLoading());
            if (response.data) {
                return response.data;
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function deleteLogo(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/activities/${id}/logo`);
            dispatch(slice.actions.endLoading());
            if (response.status === 204) {
                return true;
            } else return false
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setActivityStore(activities: Activity[]) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setActivities(activities));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}