import { createSlice } from '@reduxjs/toolkit';
import { TeamState } from 'src/@types/team/team';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: TeamState = {
    isLoading: false,
    error: null,
    data: { members:[] }
};

const slice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getTeamSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // ADD TEAM MEMBER
        addTeamMemberSuccess:(state, action) => {
            state.isLoading = false;
            state.data.members = [...state.data.members,action.payload];
            return state;
        },

        // REMOVE 
        removeTeamMemberSuccess: (state, action) => {
            state.isLoading = false;
            state.data.members = state.data.members.filter(m=>m.id!==action.payload);
            return state;
        },

        // UPDATE 
        updateTeamMemberSuccess: (state, action) => {
            state.isLoading = false;
            state.data.members = state.data.members.map(m=>m.id===action.payload.id?action.payload:m);
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getTeam(params: any = {}, cancelToken: any = null) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/companies/my/members`, { params, cancelToken });
            if (response.data) {
                // response.data.members=response.data.members.map((m:any)=>({ ...m, id:nanoid() }))
                dispatch(slice.actions.getTeamSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTeamMember(data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/companies/my/members`,data);
            if (response.data) {
                dispatch(slice.actions.addTeamMemberSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeTeamMember(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/companies/my/members/${id}`);
            if (response.status===204) {
                dispatch(slice.actions.removeTeamMemberSuccess(id));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function enableTeamMember(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/companies/my/members/${id}/enable`,{});
            if (response.status===200) {
                dispatch(slice.actions.updateTeamMemberSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function disableTeamMember(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/companies/my/members/${id}/disable`,{});
            if (response.status===200) {
                dispatch(slice.actions.updateTeamMemberSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateTeamMember(data:any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/companies/my/members/${id}`,data);
            if (response.status===200) {
                dispatch(slice.actions.updateTeamMemberSuccess(response.data));
                return response.data;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resendInviteTeamMember( id: string) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.post(`/companies/my/members/${id}/resend`,{});
            if (response.status===204) {
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }finally{
        dispatch(slice.actions.endLoading());
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}
