import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import { useSelector } from '../redux/store';

type GuestGuardProps = {
    children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const queryParamObject = Object.fromEntries(queryParams.entries());

    let { force_login } = queryParamObject;

    const { isAuthenticated, isInitialized } = useSelector(state => state.auth);

    if (isAuthenticated && force_login !== 'true') {
        return <Navigate to={PATH_DASHBOARD.root} />;
    }

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    return <>{children}</>;
}
