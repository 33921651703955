import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Avatar } from '@mui/material';
// components
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { varHover } from 'src/components/animate';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import useLocales from 'src/hooks/useLocales';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { logout } from 'src/redux/slices/auth/auth';

// -----------------------------------------------------------------

function getMenuOptions(translate: any) {
  return [
    {
      label: translate('nav_menu.secondary.profile'),
      linkTo: PATH_DASHBOARD.general.profile,
    }
  ]
}

export default function AccountPopover() {
  const navigate = useNavigate();

  const popover = usePopover();

  const { translate } = useLocales();

  const { user } = useSelector(state => state.auth)

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    let stores = ['invoice_store_new', 'invoice_store', 'quote_store', 'quote_store_new', 'delivery_note_store', 'delivery_note_store_new', 'credit_note_store', 'credit_note_store_new'];
    stores.forEach(name => localStorage.removeItem(name))

    try {
      await dispatch(logout());
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photo_url ? process.env.REACT_APP_FILE_BASE_URL + user.photo_url : ''}
          alt={user?.display_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.display_name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.display_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {getMenuOptions(translate).map((option) => (
            <MenuItem key={option.label} onClick={() => { popover.onClose(); navigate(option.linkTo); }}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>{translate('nav_menu.secondary.logout')}</MenuItem>

      </CustomPopover>
    </>
  );
}
