import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';

import { useSelector } from '../redux/store';

type AuthGuardProps = {
    children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
    const { isAuthenticated, isInitialized } = useSelector(state => state.auth)

    const { pathname } = useLocation();

    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

    const checkStates = () => {
        if (!isInitialized) {
            return <LoadingScreen />;
        }

        if (!isAuthenticated) {
            if (pathname !== requestedLocation) {
                setRequestedLocation(pathname);
            }
            return <Login />;
        }

        if (requestedLocation && pathname !== requestedLocation) {
            setRequestedLocation(null);
            return <Navigate to={requestedLocation} />;
        }
    }
    useEffect(() => {
        checkStates()
    })

    const states = checkStates();
    if (states) return states;

    return <>{children}</>;
}
